import moment from "moment";

export const PROFILE = "profile";
export const BANNER = "banner";
export const OFFER = "offer";
export const OTHER = "other";

export const PROFILE_INT = 1;
export const BANNER_INT = 2;
export const OFFER_INT = 3;
export const OTHER_INT = 0;

export function GetImageTypeName(imageTypeInt){
  switch(imageTypeInt){
    case PROFILE_INT:
      return PROFILE;
    case BANNER_INT:
      return BANNER;
    case OFFER_INT:
      return OFFER;
  }
  return OTHER;
}

export function GetStoredImageId(storedImage){ 
  return storedImage.Id !== null ? storedImage.Id : 0;
}

/**
 * @return {string}
 */
export function GetStoredImageName(storedImage){
  if(storedImage.ImageName){
    return storedImage.ImageName;
  }else{ 
    return "N/A";
  }
}

/**
 * @return {string}
 */
export function GetImageType(storedImage){
  if(storedImage.ImageType == null)
    return OTHER;
  switch(storedImage.ImageType){
    case PROFILE_INT:
      return PROFILE;
    case BANNER_INT:
      return BANNER;
    case OFFER_INT:
      return OFFER;
  }
  return OTHER;
}

export function GetImageURL(storedImage){ 
  return storedImage.ImageUrl !== null ? storedImage.ImageUrl : "N/A";
}

export function GetStoredImageIsActive(storedImage){ 
  return storedImage.IsActive !== null ? storedImage.IsActive : false;
}

export function GetStoredImageIsActiveText(storedImage){ 
  return GetStoredImageIsActive(storedImage) ? 'Yes' : 'No';
}

export function GetStoredImageIsDefault(storedImage){ 
  return storedImage.IsDefault !== null ? storedImage.IsDefault : false;
}

export function GetStoredImageIsDefaultText(storedImage){ 
  return GetStoredImageIsDefault(storedImage) ? 'Default' : '';
}

export function GetStoredImageClient(storedImage){ 
  return storedImage.Client !== null ? storedImage.Client : 0;
}