import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import  FraudShieldToolbar  from "./Components/FraudShieldToolbar";
import  FraudShieldTable  from "./Components/FraudShieldTable";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";
import { IconButton } from "@mui/material";
import withAuthorization from "../../helpers/Authorization";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Spinner from "react-bootstrap/Spinner";

import {
  GetType,
} from "helpers/LogHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const FraudShield = (props) => {
  const classes = useStyles();

  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);

  const [customSearchData, setCustomSearchData] = useState({
    inputEventType: 0,
    inputValueInt: 0,
    inputValueFloat: 0.0,
    inputValueDays: 0,
    inputUserId: 0
  })

  const [showCustomLogs, setShowCustomLogs] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { history, ...rest } = props;
  
  
  useEffect(() => {
    if(showCustomLogs){
      const fetchData = async () => {
        setIsLoading(true);
        const result = await WebService.GetCustomFraudShieldLogs(
          history, 
          customSearchData.inputEventType, 
          customSearchData.inputValueInt, 
          customSearchData.inputValueFloat, customSearchData.inputValueDays, customSearchData.inputUserId);
        if (result != null) {
          console.log(result);
          result.sort(
            (firstLog, secondLog) =>
                (firstLog.Id !== null
                  ? firstLog.Id
                  : 0)
              -
                  (secondLog.Id !== null
                      ? secondLog.Id
                      : 0)
          );
          setLogs(result);
        } else {
          history.push({ pathname: "./sign-in" });
        }
        setIsLoading(false);
      };
      fetchData();
    }
  }, [customSearchData]);

  useEffect(() => {
    if(!showCustomLogs){
      setIsLoading(true);
      const fetchData = async () => {
        const result = await WebService.GetFraudShieldLogs(history, 0);
        if (result != null) {
          console.log(result);
          result.sort(
            (firstLog, secondLog) =>
                (firstLog.Id !== null
                  ? firstLog.Id
                  : 0)
              -
              
                  (secondLog.Id !== null
                      ? secondLog.Id
                      : 0)
          );
          setLogs(result);
        } else {
          history.push({ pathname: "./sign-in" });
        }
        setIsLoading(false);
      };
      fetchData();
    }
  }, []);

  const [selectedLogStandingFilter, setSelectedLogStandingFilter] = useState(0);

  useEffect(() => {
    applyAllFilters();
  }, [selectedLogStandingFilter, logs]);

  const handleFilterSelected = (value) => {
      setSelectedLogStandingFilter(value)
  };

  const handleCustomCheckChange = (event) => {
    if(!event.target.checked){
      setShowCustomLogs(false)
      //setFilteredCompanyIdB(0)
    }else{
      setShowCustomLogs(true)
    }
  }

  const applyAllFilters = () => {
    var allLogsVar = logs;
    console.log("All logs Count "+allLogsVar.length)
    console.log(selectedLogStandingFilter)
    if(selectedLogStandingFilter != 0){
      console.log("user standing filter")
      allLogsVar = allLogsVar.filter(aLog => (GetType(aLog)) == selectedLogStandingFilter)
    }else{
      console.log("No USER filter")
    }
    setFilteredLogs(allLogsVar)
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h3>FraudShield Auto-Detected Events</h3>
        <Tooltip title="Logged Suspicious Events">
          <IconButton aria-label="?">
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </div>

      <FraudShieldToolbar  
        setCustomSearchData={setCustomSearchData} 
        showCustomLogs={showCustomLogs} 
        onFilterSelected={handleFilterSelected}/>
      <FormControlLabel
        className="mr-sm-2 ml-sm-2"
        control={<Checkbox
          checked={showCustomLogs}
          onChange={handleCustomCheckChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />}
        label={"Custom"}
      />
      <div className={classes.content}>
        {(isLoading) && <Spinner animation="border" variant="primary" />}
        {(!isLoading) && 
          <FraudShieldTable logs={filteredLogs} />
        }
      </div>
    </div>
  );
};

FraudShield.propTypes = {
  history: PropTypes.object,
};

export default withAuthorization(FraudShield);
