import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";

import { SearchInput } from "components";
import { PayoutStatus } from "../../../../helpers/UserPayoutHelper";
import { Filter } from "@mui/icons-material";
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";
import { Col, Container, Row } from "reactstrap";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  nonClickedActiveButton: {
    whiteSpace: "nowrap",
    width: "auto",
    textTransform: "none",
    border: "1px solid #51B53F",
    backgroundColor: "#FFFFFF",
    color: "#51B53F",
  },
  clickedActiveButton: {
    whiteSpace: "nowrap",
    width: "auto",
    textTransform: "none",
    backgroundColor: "#51B53F",
    color: "#FFFFFF",
    border: "none",
  },
  nonClickedPendingButton: {
    whiteSpace: "nowrap",
    width: "auto",
    textTransform: "none",
    border: "1px solid #B5A33F",
    backgroundColor: "#FFFFFF",
    color: "#B5A33F",
  },
  clickedPendingButton: {
    whiteSpace: "nowrap",
    width: "auto",
    textTransform: "none",
    backgroundColor: "#B5A33F",
    color: "#FFFFFF",
    border: "none",
  },
  nonClickedRejectedButton: {
    whiteSpace: "nowrap",
    width: "auto",
    textTransform: "none",
    border: "1px solid #B53F51",
    backgroundColor: "#FFFFFF",
    color: "#B53F51",
  },
  clickedRejectedButton: {
    whiteSpace: "nowrap",
    width: "auto",
    textTransform: "none",
    backgroundColor: "#B53F51",
    color: "#FFFFFF",
    border: "none",
  },
  wrapperWithoutLeftPadding: {
    marginLeft: "0",
    paddingLeft: "0",
  },
}));

const UserPayoutsToolbar = (props) => {
  const { className, filterPayoutsBy, currentFilter, history, ...rest } = props;
  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>

      <Grid container spacing={4}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search email"
        />
        <Grid item lg={9} sm={9} xl={9} xs={9}>
          <Button
            color="primary"
            size="large"
            variant={
              currentFilter === PayoutStatus.Pending ? "contained" : "outlined"
            }
            onClick={() => filterPayoutsBy(PayoutStatus.Pending)}
          >
            Pending
          </Button>

          <Button
            color="primary"
            size="large"
            variant={
              currentFilter === PayoutStatus.Completed
                ? "contained"
                : "outlined"
            }
            onClick={() => filterPayoutsBy(PayoutStatus.Completed)}
          >
            Approved
          </Button>
          <Button
              color="primary"
              size="large"
              variant={
                currentFilter === PayoutStatus.Rejected
                    ? "contained"
                    : "outlined"
              }
              onClick={() => filterPayoutsBy(PayoutStatus.Rejected)}
          >
            Rejected
          </Button>
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          {" "}
        </Grid>
      </Grid>
    </div>
  );
};

UserPayoutsToolbar.propTypes = {
  className: PropTypes.string,
};

export default withMasterAuthorization(UserPayoutsToolbar);
