import firebase from "firebase/app";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD8mqe_mytlZaxl_fdV1AKYubQHrwIrYpw",
    authDomain: "react-betfully.firebaseapp.com",
    databaseURL: "https://react-betfully.firebaseio.com",
    projectId: "react-betfully",
    storageBucket: "react-betfully.appspot.com",
    messagingSenderId: "835654908856",
    appId: "1:835654908856:web:d91c7125fa625bc225ed4d",
    measurementId: "G-MHJMKRQEED"
  };

  firebase.initializeApp(firebaseConfig);

  const storage = firebase.storage();

  export { storage, firebase as default };