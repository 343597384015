import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { Form } from "react-bootstrap";
import { NumericFormat } from 'react-number-format';
import { SearchInput } from 'components';
import {
  None_INT,
  Normal_INT,
  Suspended_INT,
  Banned_INT,
  GetName,
  GetEmail,
  GetUserStandingStatus,
  GetUserStandingName,
  GetInitials,
  GetPhone,
  GetCreatedDate,
  GetUserLocation,
  GetUserId,
  GetProfileURL,
} from "helpers/UserHelper";

import {
  FS_OfferMaxedOut_INT,
  FS_PointsEarnedExceededThreshold_INT,
  FS_DuplicateBankAccountLinked_INT,
  FS_DuplicateCreditCardLinked_INT,
  FS_WithdrawDepositDifference_INT,
  FS_BankAccountCountThresholdExceeded_INT,
  FS_LoggedInOutsideUS_INT,
  GetLogTypeName,

} from "helpers/LogHelper";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  customRow: {
    margin: theme.spacing(1),
    width: '25ch',
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1),
    width: '45ch',
  }
}));

const FraudShieldToolbar = props => {
  const { className,onFilterSelected, showCustomLogs, setCustomSearchData, ...rest } = props;

  const classes = useStyles();

  const [inputEventType, setInputEventType] = useState(0);
  const [inputValueInt, setInputValueInt] = useState(0);
  const [inputValueFloat, setInputValueFloat] = useState(0);
  const [inputValueDays, setInputValueDays] = useState(30);
  const [inputUserId, setInputUserId] = useState(0);

  const logFilterOptions = [ 
    FS_OfferMaxedOut_INT, 
    FS_PointsEarnedExceededThreshold_INT, 
    FS_DuplicateBankAccountLinked_INT,
    FS_WithdrawDepositDifference_INT,
    FS_BankAccountCountThresholdExceeded_INT,
    FS_LoggedInOutsideUS_INT,
    FS_DuplicateCreditCardLinked_INT
  ];

  const valueIntString = 'valueIntInput';
  const valueFloatString = 'valueFloatInput';
  const valueDaysString = 'valueDaysStringInput'
  const valueUserIdString = 'valueUserIdStringInput'

  const showField = (inputString) => {
    switch(inputEventType){
      case FS_OfferMaxedOut_INT:
        return (inputString === valueFloatString || inputString === valueDaysString);
      case FS_PointsEarnedExceededThreshold_INT:
        return (inputString === valueIntString || inputString === valueDaysString);
      case FS_WithdrawDepositDifference_INT:
        return (inputString === valueFloatString || inputString === valueDaysString);
      case FS_BankAccountCountThresholdExceeded_INT:
        return (inputString === valueIntString);
      case FS_LoggedInOutsideUS_INT:
        return false;
    }
    return false;
  }

  const retrieveFieldLabel = (inputString) => {
    switch(inputEventType){
      case FS_OfferMaxedOut_INT:
        if(inputString === valueFloatString)
          return "Percent Per Offer Completion Amount"
        if(inputString === valueDaysString)
          return "Days Back to Search"
      case FS_PointsEarnedExceededThreshold_INT:
        if(inputString === valueIntString)
          return "Minimum Point Count"
        if(inputString === valueDaysString)
          return "Days Back to Search"
      case FS_WithdrawDepositDifference_INT:
        if(inputString === valueFloatString)
          return "Max Difference Between Withdrawal and Deposit"
        if(inputString === valueDaysString)
          return "Days Back to Search"
      case FS_BankAccountCountThresholdExceeded_INT:
        if(inputString === valueIntString)
          return "Minimum Bank Count"
    }
    return "";
  }

  const showOption = (option) => {
    switch(option){
      case FS_OfferMaxedOut_INT:
      case FS_PointsEarnedExceededThreshold_INT:
      case FS_WithdrawDepositDifference_INT:
      case FS_BankAccountCountThresholdExceeded_INT:
        return true;
    }
    return false;
  }

  const submitRequest = () => {
    setCustomSearchData(
      {
        inputEventType: inputEventType,
        inputValueInt: inputValueInt,
        inputValueFloat: inputValueFloat,
        inputValueDays: inputValueDays,
        inputUserId: inputUserId
      }
    )
  }

  const handleTypeSelected = (value) => {
    setInputEventType(+value)
  };

  const myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    console.log("Name: " + nam)
    console.log("Val: " + val)
    if(nam === valueIntString){
      setInputValueInt(val);
    }else if (nam === valueFloatString){
      setInputValueFloat(val);
    }else if (nam === valueDaysString){
      setInputValueDays(val)
    }else if (nam === valueUserIdString){
      setInputUserId(val)
    }
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.customRow}>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add log
        </Button> */}
      </div>
      {!showCustomLogs &&<div style={{opacity: (!showCustomLogs)?100:0}} >
        <SearchInput
        
          className={classes.searchInput}
          placeholder="Search user"
        />

        <Form inline>
          <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
            Log Type
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            onChange = {(event) => onFilterSelected(event.target.value)}
          >
            <option value={0}>All</option>
            {logFilterOptions.map((aNum) => (
              <option value={aNum} key={aNum}>{GetLogTypeName(aNum)}</option>
            ))}
          </Form.Control>
        </Form>
      </div>}
      {showCustomLogs && 
        <div className={classes.customRow} style={{opacity: (showCustomLogs)?100:0}} >
          <Form >
            <Form.Control
                  as="select"
                  className="my-1 mr-sm-2"
                  id="inlineFormCustomSelectPref"
                  onChange = {(event) => handleTypeSelected(event.target.value)}
                >
                  <option value={0}>Select</option>
                  {logFilterOptions.filter(x => showOption(x)).map((aNum) => (
                    <option value={aNum} key={aNum}>{GetLogTypeName(aNum)}</option>
                  ))}
                </Form.Control>
            {showField(valueDaysString) && <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
              {retrieveFieldLabel(valueDaysString)}
            </Form.Label>}
            {showField(valueDaysString) && <Form.Control required placeholder="Enter Number of Days" type="number" name={valueDaysString}  onChange={myChangeHandler}/>}
            {showField(valueIntString) && <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
              {retrieveFieldLabel(valueIntString)}
            </Form.Label>}
            {showField(valueIntString) && <Form.Control required placeholder="Enter Int Val" type="number" name={valueIntString}  onChange={myChangeHandler}/>}
            {showField(valueFloatString) && <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
              {retrieveFieldLabel(valueFloatString)}
            </Form.Label>}
            {showField(valueFloatString) && <div><NumericFormat decimalScale={2} fixedDecimalScale={true} value={inputValueFloat} thousandSeparator={true} prefix={(inputEventType==FS_WithdrawDepositDifference_INT)?'$':''} suffix={(inputEventType==FS_OfferMaxedOut_INT)?'%':''} name={valueFloatString} onValueChange={(values) => {
            const {formattedValue, value} = values;
            // formattedValue = $2,223
            // value ie, 2223
            setInputValueFloat(value);//parseInt(value, 10))

          }}/></div>}
            {/* {showField(valueFloatString) && <Form.Control required placeholder="Enter Float Val" type="number" name={valueFloatString}  onChange={myChangeHandler}/>} */}
            <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
              {"User Id (optional)"}
            </Form.Label>
            <Form.Control required placeholder="Enter User Id" type="number" name={valueUserIdString}  onChange={myChangeHandler}/>
          </Form>
          <Button className="mt-2"
              color="primary"
              variant="contained"
              onClick={() => submitRequest()}
            >
              Search
            </Button>
        </div>
      }
    </div>
  );
};

FraudShieldToolbar.propTypes = {
  className: PropTypes.string
};

export default withAuthorization(FraudShieldToolbar);
