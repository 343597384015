import {
  GetMomentCompareKey,
  GetMomentKey,
  GetMomentString,
  sectionColors,
  sectionColorsLight
} from "helpers/UserDetailsHelper";

import {
  GetCompanyName,
  GetCompanyId as GetCompanyHelperId
} from "helpers/CompanyHelper";

import moment from "moment";


export const moneyOptions = {
  tooltips : {
    callbacks: {
      label: function(tooltipItems, data) {
          var label = ''//data.dataset.label || '';
          console.log(data)
          console.log(tooltipItems)
          var moneyFormat = false;
          var suffix = ""
          var prefix = ""
          if (data && data.datasets) {
              label += data.datasets[tooltipItems.datasetIndex].label+': ';
              moneyFormat = data.datasets[tooltipItems.datasetIndex].labelFormatCurrency;
              suffix = data.datasets[tooltipItems.datasetIndex].labelSuffix;
              prefix = data.datasets[tooltipItems.datasetIndex].labelPrefix;
          }
          if (tooltipItems.value !== null) {
            label += prefix;
            label += (moneyFormat)?new Intl.NumericFormat('en-US', { style: 'currency', currency: 'USD' }).format(tooltipItems.value):new Intl.NumericFormat('en-US').format(tooltipItems.value);
            label += suffix;
          }
          return label;
      }
  }
},
maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export const options = {
  tooltips : {
   
},
maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export const pieOptions = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 0,
  layout: { padding: 1 },
  tooltips: {
    enabled: true,
    mode: "index",
    intersect: false,
    borderWidth: 1,
    // borderColor: theme.palette.divider,
    // backgroundColor: theme.palette.white,
    // titleFontColor: theme.palette.text.primary,
    // bodyFontColor: theme.palette.text.secondary,
    // footerFontColor: theme.palette.text.secondary,
  },
};  

export const pieMoneyOptions = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 1 },
  // arc: {
  //   borderWidth: 100
  // },
  tooltips: {
    enabled: true,
    mode: "index",
    intersect: false,
    borderWidth: 1,
    // borderColor: theme.palette.divider,
    // backgroundColor: theme.palette.white,
    // titleFontColor: theme.palette.text.primary,
    // bodyFontColor: theme.palette.text.secondary,
    // footerFontColor: theme.palette.text.secondary,
    callbacks: {
      label: function(tooltipItems, data) {
          var label = ''//data.dataset.label || '';
          console.log(data)
          console.log(tooltipItems)
          var moneyFormat = (tooltipItems.index == 2)?false:false;
          var prefix = (tooltipItems.index == 2)?"":"";
          var suffix = (tooltipItems.index == 2)?"":"";
          
          if (data && data.datasets) {
              label += data.labels[tooltipItems.index]+': ';
              moneyFormat = data.datasets[0].labelFormatCurrency[tooltipItems.index]
              prefix = data.datasets[0].labelPrefix[tooltipItems.index]
              suffix = data.datasets[0].labelSuffix[tooltipItems.index]
          }
          if (tooltipItems.value !== null) {
              label += prefix;
              label += (moneyFormat)?new Intl.NumericFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.datasets[0].data[tooltipItems.index]):new Intl.NumericFormat('en-US').format(data.datasets[0].data[tooltipItems.index]);
              label += suffix;
          }
          return label;
      }
  }
  },
};  


export function FormatDataForPie(dataMap, barChartData, RetrieveFieldFunc, colors, useOfferId){
  var dataSet = {
    datasets: [{
      data: [],
      backgroundColor: [],
      borderWidth: 2,
      borderColor: [],
      hoverBorderColor: [],
      labelPrefix:[],
      labelSuffix:[],
      labelFormatCurrency:[]
    }],
    labels: []
  }
  if(barChartData != null && barChartData.datasets != null && barChartData.datasets.length > 0){
      barChartData.datasets.map(x =>
        dataSet.datasets[0].data.push(
          RetrieveFieldFunc((useOfferId)?x.offerId:x.companyId, dataMap, x.tEFValue))
      )
    }
    
    barChartData.datasets.map(x => 
      dataSet.datasets[0].backgroundColor.push(
        x.backgroundColor)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].borderColor.push(
        colors.common.white)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].hoverBorderColor.push(
        x.borderColor)
    )
    barChartData.datasets.map(x => 
      dataSet.labels.push(x.label)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].labelPrefix.push(
        x.labelPrefix)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].labelSuffix.push(
        x.labelSuffix)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].labelFormatCurrency.push(
        x.labelFormatCurrency)
    )
  
  return dataSet;
}


export function GenerateDataForChart(showAllStats, fullDataList, companyList, dateRangeLength, dateUnit, entryFieldList,
  GetEntryFieldPrefixString, GetEntryFieldSuffixString, GetEntryFieldIsCurrency, GetEntryFieldName, GetEntryDateString,
  GetMapField, CreateEntryMapFunc){
  
  var dateArray = Array(dateRangeLength); 
  var dataSets = [];
  if(entryFieldList != null && entryFieldList.length > 0 && companyList != null && companyList.length > 0){
    if(showAllStats){
      entryFieldList.map(anEntryField => 
          dataSets.push(CreateDataSetForCompanySame(companyList[0], anEntryField, 
            GetEntryFieldPrefixString, GetEntryFieldSuffixString, 
            GetEntryFieldIsCurrency, GetEntryFieldName)
          )
        )
    }else if(companyList != null && Array.isArray(companyList)){
      dataSets = companyList.map(comp => 
        CreateDataSetForCompany(comp, entryFieldList[0], GetEntryFieldPrefixString, GetEntryFieldSuffixString, GetEntryFieldIsCurrency)
      );
    }
  const res = Array.apply(0, dateArray).map(
    function(_,i){
      return moment().add(1-dateRangeLength+i, GetMomentKey(dateUnit))
    }
  )

  var compareFunc = (aDate, labelDate) => {
    return moment(aDate).isSame(labelDate, GetMomentCompareKey(dateUnit))
  }

  var index;
  for (index = 0; index < res.length; index++) {
    var partialTransactionList = fullDataList.filter(
      anEntry => compareFunc(GetEntryDateString(anEntry), res[index])
    );
    var entryMap = CreateEntryMapFunc(partialTransactionList);
    
    if(showAllStats && companyList.length > 0){
      for (let inde = 0; inde < dataSets.length; inde++) {
        var anEntryField = entryFieldList[inde];
        var aDataSet = dataSets[inde];
        aDataSet.data.push(GetMapField(aDataSet.companyId, entryMap, anEntryField))
      }
    }else{
      dataSets.map(companySet => 
        companySet.data.push(GetMapField(companySet.companyId, entryMap, entryFieldList[0])))
    }
  }

  const labelList = res.map(x => x.format(GetMomentString(dateUnit)))


  var combined = {
    labels: labelList,
    datasets: dataSets
  }

  return combined;
  }else{
    return null
  }

}

function CreateDataSetForCompany(company, entryField, prefixFunc, suffixFunc, currencyFunc){
  return {
    //offerId: GetOfferItemId(null),
    companyId: GetCompanyHelperId(company),
    label: GetCompanyName(company),
    tEFValue: entryField,
    labelPrefix: prefixFunc(entryField),
    labelSuffix:suffixFunc(entryField),
    labelFormatCurrency:currencyFunc(entryField),
    backgroundColor: sectionColors[(GetCompanyHelperId(company)%sectionColors.length)],
    data: [],
    fill: false,
    borderWidth: 2,
    borderColor: sectionColorsLight[(GetCompanyHelperId(company)%sectionColorsLight.length)],
    lineTension: 0,   
    hoverOffset: 4
  }
}

function CreateDataSetForCompanySame(company, entryField, prefixFunc, suffixFunc, currencyFunc, entryNameFunc){
  return {
    companyId: GetCompanyHelperId(company),
    tEFValue: entryField,
    label: entryNameFunc(entryField),
    labelPrefix: prefixFunc(entryField),
    labelSuffix:suffixFunc(entryField),
    labelFormatCurrency:currencyFunc(entryField),
    backgroundColor: sectionColors[((GetCompanyHelperId(company)+entryField)%sectionColors.length)],
    data: [],
    fill: false,
    borderWidth: 2,
    borderColor: sectionColorsLight[((GetCompanyHelperId(company)+entryField)%sectionColorsLight.length)],
    lineTension: 0,   
    hoverOffset: 4
  }
}