import moment from "moment";

export function GetAdminUserId(adminUser){ 
  return adminUser.Id !== null ? adminUser.Id : "-";
}

/**
 * @return {string}
 */
export function GetEmail(adminUser) {
  if (adminUser.Email) {
    return adminUser.Email;
  } else {
    return "-";
  }
}

/**
 * @return {string}
 */
export function GetUserName(adminUser){
  if(adminUser.UserName){
    return adminUser.UserName;
  }else{ 
    return "-";
  }
}

export function GetUserRole(adminUser){ 
  return adminUser.Role !== null ? adminUser.Role : "-";
}

export function GetUserCompany(adminUser){ 
  return adminUser.CompanyName !== null ? adminUser.CompanyName : "-";
}

export function GetUserCompanyId(adminUser){ 
  return adminUser.CompanyId !== null ? adminUser.CompanyId : 0;
}

/**
 * @return {string}
 */
export function GetDateCreated(adminUser) {
  return adminUser.DateCreated !== null ? moment(adminUser.DateCreated).format("MMM DD, YYYY"): "-"; 
}