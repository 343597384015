import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import PropTypes from "prop-types";
import UserPayoutsTable from './Components/UserPayoutsTable'
import UserPayoutsToolbar from './Components/UserPayoutsToolbar'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import UserPayoutsFooterButtons from './Components/UserPayoutsFooterButtons'
import {GetStatusAsInt, PayoutStatus} from '../../helpers/UserPayoutHelper'
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import Spinner from 'react-bootstrap/Spinner';
import SessionHelper from '../../helpers/SessionHelper'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

const UserPayoutList = props => {
  const { history, ...rest } = props;
  const classes = useStyles();
  const [UserPayouts, setUserPayouts] = useState([]);
  const [typeOfConfirmation, setTypeOfConfirmation] = useState();
  const [SelectedUserPayouts, setSelectedUserPayouts] = useState(new Map());
  const [filteredUserPayouts, setFilteredUserPayouts] = useState([]);
  const [currentTypeOfFilteredPayouts, setCurrentTypeOfFilteredPayouts] = useState(PayoutStatus.Pending);
  const [showCompletion, setShowCompletion] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popupData, setPopupdata] = useState({
          title: "Payouts Sent", 
          body: "Payouts successfully sent!"
  });
  
  const handlePayoutConfirmation = () => {
    console.log("Inside handlePayoutConfirmation");
    setTypeOfConfirmation("AUTHORIZE")
    setPopupdata({
      title: "Payout Confirmation",
      body: `Are you sure you want to process payments for \n ${SelectedUserPayouts.size} customers?`
    });
    handleShowConfirmation()
  }  
  
  const handlePayoutRejectionConfirmation = () => {
    console.log("Inside handlePayoutRejectionConfirmation")
    setTypeOfConfirmation("REJECT")
    setPopupdata({
      title: "Payout Rejection Confirmation",
      body: `Are you sure you want to reject payments for \n ${SelectedUserPayouts.size} customers?`
    });
    handleShowConfirmation()
  }
  const handlePayoutAuthorizationSuccess = () => setPopupdata({
    title: "Payouts Sent",
    body: "Payouts successfully sent!"
  });
  const handlePayoutAuthorizationError = (message) => setPopupdata({
    title: "Internal Error with payout",
    body: `${message}`
  });
  
  const handlePayoutRejectionSuccess = () => setPopupdata({
    title: "Rejection Completed",
    body: "Payouts rejections successfully processed!"
  });
  const handlePayoutRejectionError = (message) => setPopupdata({
    title: "Error",
    body: `${message}`
  });
  
  const dataExists = (data) => {
    console.log("Data value is");
    console.log(data);
    return data !== undefined && data !== null && data !== ""; 
  }
  
  const handleCloseCompletion = () => {
    if(isLoading === false){
      setShowCompletion(false);
    }
  }
  const handleShowCompletion = () => setShowCompletion(true);
  
  const handleShowConfirmation = () => setShowConfirmation(true);
  const handleCloseConfirmation = () => {
    if(isLoading === false) {
      setShowConfirmation(false);
    }
  }
    
  const handleSendPayment = () => {
    let payoutRequestIds = GetPayoutRequestAsList(); 
    console.log("Send Payment")
    console.log("Payout request Ids:");
    console.log(payoutRequestIds);
    if(isLoading === false){
      if(SelectedUserPayouts.size > 0) {
        setIsLoading(true);
        WebService.CompletePaypalPayouts(history, payoutRequestIds)
          .then(data => {
            if(dataExists(data)) {
              handlePayoutAuthorizationError(data.Message);
              handleCloseConfirmation();
              handleShowCompletion();
            }else{
              handlePayoutAuthorizationSuccess();
              handleCloseConfirmation();
              handleShowCompletion();
              const statusAsInt = GetStatusAsInt(PayoutStatus.Completed);
              changeStatusOnPayoutsWithId(statusAsInt);
            }
            setIsLoading(false)
          });
      }
    }
  }
  
  const handleRejectPayment = () => {
    let payoutRequestIds = GetPayoutRequestAsList();
    console.log("Reject Payment")
    console.log("Payout request Ids:");
    console.log(payoutRequestIds);
    if(isLoading === false){
      if(SelectedUserPayouts.size > 0) {
        setIsLoading(true)
        WebService.RejectUsersPaypalPayout(history, payoutRequestIds)
          .then(data => {
            if(dataExists(data)) {
              handlePayoutRejectionError(data.Message);
              handleCloseConfirmation();
              handleShowCompletion();
            }else{
              handlePayoutRejectionSuccess();
              handleCloseConfirmation();
              handleShowCompletion();
              const statusAsInt = GetStatusAsInt(PayoutStatus.Rejected)
              changeStatusOnPayoutsWithId(statusAsInt);
            }
            setIsLoading(false)
          });
      } 
    }
  }
  
  const changeStatusOnPayoutsWithId = (newStatus) => {
    for(var id of SelectedUserPayouts.keys()){
      const payoutIndex = UserPayouts.findIndex(payout => payout.Id === id);
      const currentFilteredIndex = filteredUserPayouts.findIndex(filteredPayout => filteredPayout.Id === id);
      
      if(payoutIndex > -1){
        UserPayouts[payoutIndex].Status = newStatus;
      }
      
      if(currentFilteredIndex > -1){
        filteredUserPayouts.splice(currentFilteredIndex, 1);
      }else{
        console.log("Error removing from page")
      }
      
    }
    setFilteredUserPayouts(filteredUserPayouts)
    setUserPayouts(UserPayouts)
    setSelectedUserPayouts(new Map());
  }
  
  const GetPayoutRequestAsList = () => {
    let payoutRequestIds = [];
    var userPayoutsAsArray = [...SelectedUserPayouts.values()];
    for (let index = 0; index < userPayoutsAsArray.length; index++) {
      payoutRequestIds.push(userPayoutsAsArray[index].Id);
    }
    return payoutRequestIds;
  }
  
  const GetUserPayoutsFilteredBy = (filter, uPayouts) => {
    let filteredPayoutRequests = [];
    var userPayoutsAsArray = []; 
    var statusIntValue = 0;
    if(uPayouts === undefined){
      userPayoutsAsArray = [...UserPayouts.values()];
    }else{
      userPayoutsAsArray = [...uPayouts.values()];
    }
    console.log("Filter Type:");
    console.log(filter);
    
    statusIntValue = GetStatusAsInt(filter);
   
    for (let index = 0; index < userPayoutsAsArray.length; index++) {
      if(userPayoutsAsArray[index].Status === statusIntValue) {
        filteredPayoutRequests.push(userPayoutsAsArray[index]);
      }
    }
    setCurrentTypeOfFilteredPayouts(filter);
    setFilteredUserPayouts(filteredPayoutRequests);
    
  }
  
  const addToListOfSelectedPayouts = (selectedPayout) => {
    console.log("Before: ");
    console.log(SelectedUserPayouts);
    console.log("Inside addToListOfSelectedPayouts")
    SelectedUserPayouts.set(selectedPayout.Id, selectedPayout);
    console.log("After: ");
    console.log(SelectedUserPayouts)
  }
  
  const removeFromListOfSelectedPayouts = (unSelectedPayout) => {
    console.log("Before: ");
    console.log(SelectedUserPayouts);
    console.log("Inside removeFromListOfSelectedPayouts")
    SelectedUserPayouts.delete(unSelectedPayout.Id);
    console.log("After: ");  
    console.log(SelectedUserPayouts)
  }
  
  useEffect(() => {
    if(SessionHelper.isMasterAdmin()) {
      setIsLoading(true);
      const fetchData = async () => {
        const result = await WebService.GetUserPayouts(history);
        if (result != null) {
          console.log("GetUserPayout results:");
          console.log(result);
          setUserPayouts(result);
          GetUserPayoutsFilteredBy(PayoutStatus.Pending, result)
        } else {

        }
        setIsLoading(false);
      };
      fetchData();
    }
  }, []);

  return (
      <div className={classes.root}>
          {(isLoading) && <Spinner animation="border" variant="primary" />}

          {(!isLoading) && 
            <div>
              <Modal
                  className={classes.content}
                  size="lg"
                  show={showCompletion}
                  onHide={handleCloseCompletion}
                  centered>
                  <Modal.Header closeButton>
                    <Modal.Title>{popupData.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{popupData.body}</Modal.Body>
                  <Modal.Footer>
                  <Button variant="primary" onClick={handleCloseCompletion}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                className={classes.content}
                show={showConfirmation}
                onHide={handleCloseConfirmation}
                centered>
                <Modal.Header closeButton>
                <Modal.Title>{popupData.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{popupData.body}</Modal.Body>
                <Modal.Footer>
                <Button
                disabled={isLoading}
                variant="primary"
                onClick={typeOfConfirmation === "AUTHORIZE" ? handleSendPayment : handleRejectPayment}>
                Yes
                </Button>
                <Button
                disabled={isLoading}
                variant="secondary"
                onClick={handleCloseConfirmation}>
                Cancel
                </Button>
                </Modal.Footer>
              </Modal>
  
              <UserPayoutsToolbar
              filterPayoutsBy={GetUserPayoutsFilteredBy}
              currentFilter={currentTypeOfFilteredPayouts}
              history={history}
              />
  
              <div className={classes.content}>
              <UserPayoutsTable
              UserPayouts={filteredUserPayouts}
              AddToListOfSelectedPayouts={addToListOfSelectedPayouts}
              RemoveFromListOfSelectedPayouts={removeFromListOfSelectedPayouts}
              showSelectBox={currentTypeOfFilteredPayouts === PayoutStatus.Pending}
              history={history}
              />
              </div>
              { currentTypeOfFilteredPayouts === PayoutStatus.Pending && 
                <UserPayoutsFooterButtons
                    HandleSendPayments={handlePayoutConfirmation}
                    HandleRejectPayouts={handlePayoutRejectionConfirmation}
                    history={history}
                />
              }
            </div>
          }
      </div>
  );
};
UserPayoutList.propTypes = {
  history: PropTypes.object
};

export default withMasterAuthorization(UserPayoutList);