import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  IconButton,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  CardActions,
  Avatar,
  Checkbox,
  TableSortLabel,
  Collapse,
  Paper,
  Box,
  colors
} from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Grid3x3Icon from '@mui/icons-material/Filter1';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Modal from 'react-bootstrap/Modal';
import WebService from "../../api/WebServices";
import { Link as RouterLink, withRouter } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import clsx from "clsx";

import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import PerfectScrollbar from "react-perfect-scrollbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import { isConstructorDeclaration } from "typescript";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ToastProvider, useToasts } from "react-toast-notifications";
import {
  GetOfferId,
  GetTitle,
  GetTitle2,
  GetDescription,
  GetActivationCount,
  GetClientName,
  GetReward,
  GetDateCreated,
  GetStartDate,
  GetFirstPreviousVersionDate,
  GetExpirationDate,
  GetOfferCriteriaCount,
  GetCashBackPercentage,
  GetOfferURL,
  GetTermsURL,
  GetExtraURL,
  GetCompanyIcon,
  GetCompanyId as GetOfferCompanyId
} from "helpers/OfferHelper";
import SessionHelper from '../../helpers/SessionHelper'
import {
  MaxDepositValues, 
  CashBackValues, 
  MaxDaysValues, 
  InEqualityValues, 
  MaxTransactionValues } from "../../constants/constants.js";
import { 
  GetCompanyName,
  GetCompanyId
} from 'helpers/CompanyHelper'

import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

import withAuthorization from "../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  tableRow: {
    height: 10,
    padding:0,
    backgroundColor: colors.grey[50]
  },
  tableCell: {
    height: 15,
    //width: 150,
    paddingLeft:3,
    paddingRight:3,
    paddingTop:1,
    paddingBottom:1,
  },
  smallCell: {
    width: 10
  },
  iconCell: {
    width: 150
  },
  stateCell:{
    width: 100
  },
  smallCell2: {
    width: 15
  },
  tableCellSmallText: {
    height: 10,
    width: 10,
    textAlign: 'center',
    paddingLeft:1,
    paddingRight:1,
    paddingTop:1,
    paddingBottom:1,
    fontSize: '10pt'
  },
  imageContainer: {
    height: 35,
    width: 50,
    margin: "auto",
    marginBottom: "0px",
    borderRadius: "1px",
    overflow: "hidden",
    display: "flex",
    alignItems: "normal",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    objectFit : "contain"
  },
  textContainer: {
    display: "block",
    width: "200px",
    overflow: "hidden",
  }
}));

const options = {
  Active: "Active",
  Paused: "Paused",
  Pending: "Pending",
  Removed: "Removed",
  Approved: "Approved",
  Expired: "Expired"
};


const AdminOfferView = (props) => {
  const classes = useStyles();
  const { history, className, ...rest } = props;
  
  //History variables
  //const dupeOffer = props.location.state.dupeOffer;

  const { addToast } = useToasts();
  const [allOffers, setAllOffers] = useState([]);
  const [userCriteriaList, setUserCriteria] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [tabSelected, setTabSelected] = useState(options.Active);
  const [activeCount, setActiveCount] = useState(0);
  const [pausedCount, setPauseCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [expiredCount, setExpiredCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const provinceList = SessionHelper.getProvinceList();

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.COL1_ASC);
  const sortTypeList = [
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR
  ];
  const dataRetrieveFunctionList = [
    GetClientName,
    GetClientName,
    GetTitle, 
    GetTitle,
  ];

  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, filteredOffers, sortTypeList, dataRetrieveFunctionList, GetOfferId, undefined, setSelectedSort) 
  };

  const handleIsSortActive = (columnType) => {
    return isSortActive(columnType, selectedSort)
  };

  const [closeOfferDetail, setCloseOfferDetail] = useState(false)
    const [showOfferDetail, setShowOfferDetail] = useState(false);
    const handleCancelOfferDetail = () => setShowOfferDetail(false);
    const handleCloseOfferDetail = () => {setCloseOfferDetail(!closeOfferDetail); setShowOfferDetail(false);}
    const handleShowOfferDetail = (theOffer) => {
      setSelectedOffer(theOffer)
        console.log("The Company: " + theOffer.Id);
        // setCompanyIdInput(GetCompanyId(theCompany));
        // setCompanyStoredImageId(GetCompanyStoredImageId(theCompany))
        // setCompanyInput(GetCompanyName(theCompany));
        // setCompanyUrlInput(GetURL(theCompany));
        // setCompanyImgInput(GetImageURL(theCompany));
        setShowOfferDetail(true);
    }

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    var columnToUse = COLUMNS_SORT.COL1_ASC;
    if(selectedSort % 2 == 0){
      columnToUse = selectedSort+1;
    }else if(selectedSort % 2 == 1){
      columnToUse = selectedSort-1;
    }
    toggleSortFunc(selectedSort, columnToUse, allOffers, sortTypeList, dataRetrieveFunctionList, GetOfferId, undefined, setSelectedSort)
    var allOffersVar = allOffers.filter(applyAllFilters)
    if(allOffersVar!= null){
      setFilteredOffers(allOffersVar);
    }
  }, [selectedCompanyFilter, allOffers, tabSelected]);

  useEffect(() => {
    var allOffersVar = allOffers.filter(filterCompanies)
    if(allOffersVar!= null){
      console.log(allOffersVar);
      var activeCountA=0;
      var pauseCountA = 0;
      var pendingCountA=0;
      var rejectedCountA = 0;
      var expiredCountA=0;
      var approvedCountA=0;
      for (let index = 0; index < allOffersVar.length; index++) {
        switch (allOffersVar[index].Status) {
          case 1:
            activeCountA++;
            break;
          case 2:
            pendingCountA++;
            break;
          case 3:
            rejectedCountA++;
            break;
          case 0:
            pauseCountA++;
            break;
          case 4:
            expiredCountA++;
            break;
          case 5:
            approvedCountA++;
            break;
          default:
            break;
        }
      }
      setActiveCount(activeCountA);
      setPauseCount(pauseCountA);
      setPendingCount(pendingCountA);
      setRejectedCount(rejectedCountA);
      setExpiredCount(expiredCountA);
      setApprovedCount(approvedCountA);
    }
  }, [selectedCompanyFilter, allOffers]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await WebService.GetActiveAndPendingOffers(history);
      if (result != null) {
        console.log(result);
        if(result.WebOffers != null){
          setAllOffers(result.WebOffers);
          setUserCriteria(result.AllOfferCriteria);
        }

        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
        const fetchData = async () => {
            const result = await WebService.GetAllCompanies(history, undefined);
            if (result != null) {
                // Temporary sorting solution:
                result.sort((firstCompany, secondCompany) =>
                GetCompanyName(firstCompany).localeCompare(GetCompanyName(secondCompany)));
                setCompanies(result)
            } else {
            }
        };
        fetchData();
}, []);

  function getOfferList(offer) {
    switch (tabSelected) {
      case options.Active:
        return offer.Status == 1;
      case options.Pending:
        return offer.Status == 2;
      case options.Rejected:
        return offer.Status == 3;
      case options.Paused:
        return offer.Status == 0;
      case options.Expired:
        return offer.Status == 4;
      case options.Approved:
        return offer.Status == 5;
      default:
        return false;
    }
    return false;
  }

  const handleDupeOffer = (offer) => {
    history.push({
      pathname: "./createOffer",
      state: { dupeOffer: offer, userCriteriaList: userCriteriaList },
    });
  };

  const handleEditOffer = (offer) => {
    history.push({
      pathname: "./EditOffer",
      state: { offer: offer, criteriaList: userCriteriaList, companySelected: offer.Client },
    });
  };

  const applyAllFilters = (offer) => {
    return filterCompanies(offer) && getOfferList(offer);
  }

  const filterCompanies = (offer) =>{
    if(selectedCompanyFilter != 0){
      return GetOfferCompanyId(offer) == selectedCompanyFilter;
    }else{
        return true;
    }
}

  const handleFilterSelected = (value) => {
    setSelectedCompanyFilter(value)
  };

  function renderSwitch(offer) {
    switch (tabSelected) {
      case options.Active:
        return;
        //  (
        //   <Button
        //     color="alert"
        //     size="small"
        //     variant="text"
        //     onClick={(event) => handlePauseOffer(event, offer)}
        //   >
        //     Delete
        //   </Button>
        // );
      case options.Pending:
        return (
          <>
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={(event) => handleApproveOffer(event, offer)}
            >
              Approve
            </Button>
            <Button
              color="alert"
              size="small"
              variant="text"
              onClick={(event) => handleRejectOffer(event, offer)}
            >
              Remove
            </Button>
          </>
        );
      case options.Rejected:

      case options.Paused:

      case options.Approved:

      case options.Expired:
        // return (
        //   <Button
        //     color="primary"
        //     size="small"
        //     variant="text"
        //     onClick={(event) => handleApproveOffer(event, offer)}
        //   >
        //     Activate
        //   </Button>
        // );
      default:
        return;
    }
  }

  const handleApproveOffer = async (event, offer) => {
    setIsLoading(true);
    const result = await WebService.UpdateOfferStatus(history, offer.Id, 5);
    if (result != null) {
      if(result.WebOffers != null){
        setAllOffers(result.WebOffers);
      }
      setTabSelected(options.Active);
      setIsLoading(false);
    } else {
      history.push({ pathname: "./sign-in" });
    }
  };

  const handleRejectOffer = async (event, offer) => {
    setIsLoading(true);
    const result = await WebService.UpdateOfferStatus(history, offer.Id, 3);
    if (result != null) {
      if(result.WebOffers != null){
        setAllOffers(result.WebOffers);
      }
      setTabSelected(options.Rejected);
      setIsLoading(false);
    } else {
      history.push({ pathname: "./sign-in" });
    }
  };

  const handleApproveOfferRequest = async (event, requestId) => {
    setIsLoading(true);
    const result = await WebService.AcceptOfferChangeRequest(history, requestId);
    if (result != null) {
      if(result.WebOffers != null){
        setAllOffers(result.WebOffers);
      }
      setTabSelected(options.Active);
      setIsLoading(false);
    } else {
      history.push({ pathname: "./sign-in" });
    }
  };

  const handleRejectOfferRequest = async (event, requestId) => {
    setIsLoading(true);
    const result = await WebService.RejectOfferChangeRequest(history, requestId);
    if (result != null) {
      if(result.WebOffers != null){
        setAllOffers(result.WebOffers);
      }
      setTabSelected(options.Active);
      setIsLoading(false);
    } else {
      history.push({ pathname: "./sign-in" });
    }
  };

  const handleExportAdminOffers = async () => {
    console.log("Gathering report")
    const result = await WebService.ExportAdminOffers(history);
    addToast((result != null)?"Export Success":"Export Failed", {
      appearance: (result != null)?"success":"error",
      autoDismiss: true,
    });
  };

  const handleTabSelect = (event, selection) => {
    setTabSelected(selection);
  };

  const handlePauseOffer = async (event, offer) => {
    setIsLoading(true);
    const result = await WebService.UpdateOfferStatus(history, offer.Id, 0);
    if (result != null) {
      if(result.WebOffers != null){
        setAllOffers(result.WebOffers);
      }
      setTabSelected(options.Active);
      setIsLoading(false);
    } else {
      history.push({ pathname: "./sign-in" });
    }
  };

  const handlePageChange = (event, page) => {
    console.log("Page "+page);
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };

  const useRowStyles = makeStyles((theme) => ({
    '& > *': {
      borderBottom: 'unset',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      backgroundColor: colors.blueGrey[100]
    },
  }));

  const CreateLocationRow = (location) => {
    var provinceList = SessionHelper.getProvinceList();
    if(provinceList != null){
    const foundLocation = provinceList.find(x => x.Id == location.ProvinceId);
      if(foundLocation != null){
        return (
          <Grid container spacing={4}>
                      <Grid align="left" item xs={4}>
                          <div>{foundLocation.Name}</div>
                      </Grid>
                    </Grid>
        )
      }
    }
  }

  const handleCopy = async (offerRowText) => {
    try {
      await navigator.clipboard.writeText(offerRowText);
      addToast("Offer Id copied to clipboard!", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      addToast("Failed to copy text", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  function OfferRow(props) {
    const { row, dupeOffer, editOffer } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    return (
      <React.Fragment>
        
        <TableRow
          // onClick={(event) => handleShowOfferDetail(row)}
          className={classes.tableRow}
            key={GetOfferId(row)}
          >
            <TableCell className={classes.iconCell}>
              <Grid container columns={15} spacing={1} direction="row" >
                <Grid item md={3} xs={5}>
                <IconButton aria-label="expand row" size="small" onClick={(event) => handleCopy(GetOfferId(row))}>
                      <Grid3x3Icon /> 
                    </IconButton>
                  {/* <CopyToClipboard text={GetOfferId(row)}
                    onCopy={() => addToast("Offer Id Copied!", {
                      appearance: "success",
                      autoDismiss: true,
                    })}>
                      <IconButton aria-label="expand row" size="small" >
                      <Grid3x3Icon /> 
                    </IconButton>
                  </CopyToClipboard> */}
                  </Grid>
                  <Grid item md={3} xs={5}>
                <IconButton aria-label="expand row" size="small" 
                  
                        onClick={(event) => dupeOffer(row)}
                      >
                        <FileCopyIcon/>
                      </IconButton></Grid>
                      {(tabSelected != options.Paused &&  tabSelected != options.Expired) && 
                      <Grid item md={3} xs={5}>
                <IconButton aria-label="expand row" size="small" 
                        onClick={(event) => editOffer(row)}
                      >
                        <CreateIcon/>
                      </IconButton></Grid>}
            </Grid>
          </TableCell>
          <TableCell className={classes.stateCell}>
            {/* <Grid container columns={15} spacing={1} direction="row" >
                  {provinceList.filter(y => row.OfferLocations != null && row.OfferLocations.map(x => x.ProvinceId).includes(y.Id)).map(x => (
                  <Grid item md={3} xs={5}>
                    <label>{(x.Abbr == null)?x.Name:x.Abbr}</label>
                    </Grid>))}
                    
                  
                  </Grid> */}
          </TableCell>
            <TableCell>
              {" "}
              <div className={classes.imageContainer}>
                <img
                  alt="Product"
                  className={classes.image}
                  
                  src={GetCompanyIcon(row)}
                />
              
            </div>
            </TableCell>
            <TableCell className={classes.tableCell} >{row.ClientName}</TableCell>
            <TableCell className={classes.tableCell} >{GetTitle(row)+((GetTitle2(row).length > 0)?(" "+GetTitle2(row)):"")}</TableCell>
            <TableCell className={classes.tableCell}  >{GetDescription(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{GetFirstPreviousVersionDate(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{GetStartDate(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{GetExpirationDate(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{GetReward(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{row.ActivationCount}</TableCell>
            {/* <TableCell className={classes.tableCellSmallText}>{GetDateCreated(row)}</TableCell> */}
            {/* <TableCell className={classes.tableCellSmallText}>{GetOfferCriteriaCount(row)}</TableCell> */}
            {/* <TableCell className={classes.tableRow}> {renderSwitch(row)}</TableCell> */}
            <TableCell className={classes.tableRow}>
            {tabSelected == options.Active && 
            <IconButton aria-label="expand row" size="small" 
              onClick={(event) => handlePauseOffer(event, row)}
            >
              <DeleteIcon/>
            </IconButton>}
              {false && <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>}
          </TableCell>
        </TableRow>
        
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentHeader}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Form inline>
          <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
            Company 
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            custom
            onChange = {(event) => handleFilterSelected(event.target.value)}
          >
            <option value={0}>All</option>
            {companies.map((aNum) => (
              <option value={GetCompanyId(aNum)} key={GetCompanyId(aNum)}>{GetCompanyName(aNum)}</option>
            ))}
          </Form.Control>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          className={'m-3 justify-right'}
          onClick={(event) => handleDupeOffer(undefined)}
        >
          Create Offer
        </Button>

        <Button
          color="primary"
          size="small"
          variant="outlined"
          className={'m-3 justify-right'}
          onClick={(event) => handleExportAdminOffers()}
        >
          Export Offer Report
        </Button>
        </Form>
       
       

      </div>
      <Grid container spacing={4}>
        <Grid item lg={9} sm={9} xl={9} xs={9}>
  
          <Button
            color="primary"
            size="large"
            className={"m-2"}
            variant={(tabSelected === options.Active) ? "contained" : "outlined"}
            onClick={(event) => handleTabSelect(event, options.Active)}
          >
            Active {activeCount}
          </Button>
          <Button
            color="primary"
            className={"m-2"}
            size="large"
            variant={(tabSelected === options.Pending) ? "contained" : "outlined"}
            onClick={(event) => handleTabSelect(event, options.Pending)}
          >
            Pending {pendingCount}
          </Button>
          <Button
            color="primary"
            className={"m-2"}
            size="large"
            variant={(tabSelected === options.Rejected) ? "contained" : "outlined"}
            onClick={(event) => handleTabSelect(event, options.Rejected)}
          >
            Rejected {rejectedCount}
          </Button>
          <Button
            color="primary"
            className={"m-2"}
            size="large"
            variant={(tabSelected === options.Expired) ? "contained" : "outlined"}
            onClick={(event) => handleTabSelect(event, options.Expired)}
          >
            Expired {expiredCount}
          </Button>
          <Button
            color="primary"
            className={"m-2"}
            size="large"
            variant={(tabSelected === options.Approved) ? "contained" : "outlined"}
            onClick={(event) => handleTabSelect(event, options.Approved)}
          >
            Approved {approvedCount}
          </Button>
          <Button
            color="primary"
            className={"m-2"}
            size="large"
            variant={(tabSelected === options.Paused) ? "contained" : "outlined"}
            onClick={(event) => handleTabSelect(event, options.Paused)}
          >
            Deleted {pausedCount}
          </Button>
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          {" "}
        </Grid>
      </Grid>

      <Card {...rest} className={clsx(className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.smallCell}></TableCell>
                    <TableCell className={classes.smallCell}></TableCell>
                    <TableCell className={classes.smallCell2}></TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL1_ASC) || handleIsSortActive(COLUMNS_SORT.COL1_DESC)}>
                        Operator
                      </TableSortLabel>
                  </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL2_ASC) || handleIsSortActive(COLUMNS_SORT.COL2_DESC)}>
                        Offer Title
                      </TableSortLabel>
                  </TableCell>
                 
                  <TableCell >
                    Offer Description
                  </TableCell>
                  <TableCell>
                    Edit Date
                  </TableCell>
                  <TableCell >
                    Start Date
                  </TableCell>
                  <TableCell> 
                    Expire Date
                  </TableCell>
                  <TableCell>
                    Reward Amount
                  </TableCell>
                  <TableCell>
                    Claimed
                  </TableCell>
                  <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {(isLoading) && <Spinner animation="border" variant="primary" />}
                {(!isLoading) && 
                <TableBody>
                  {filteredOffers.slice((page)*rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((offer) => (
                    <OfferRow key={GetOfferId(offer)} row={offer} dupeOffer={handleDupeOffer} editOffer={handleEditOffer}/>
                    
                  ))}
                </TableBody>
}
              </Table>
            </div>
            <Modal
              className={classes.content}
              show={showOfferDetail}
              onHide={handleCloseOfferDetail}
              centered>
              <Modal.Header closeButton>
                <Modal.Title>{"Offer Details"}</Modal.Title>
              </Modal.Header>
              <Modal.Body><div>{"User Filters"}</div>
              {selectedOffer != undefined && selectedOffer.OfferCriteria != undefined && selectedOffer.OfferCriteria.length == 0 && <label>
                    {"None applied"} 
                    </label>}
              <ul>{selectedOffer != undefined && selectedOffer.OfferCriteria != undefined && selectedOffer.OfferCriteria.length > 0 &&
              selectedOffer.OfferCriteria.map((offer) => (
                // <div>{offer.CriteriaDisplayName}</div>
                <li>
          {(offer.CriteriaDisplayName+"").split(' ').map((aValue) =>
                  (aValue == '{0}')?(<label>{(" "+offer.FirstValue+" ")} </label>)
                  :((aValue == '{1}')?
                  (<label>
                    {(offer.FirstEquality !== undefined && offer.FirstEquality > 0)?(" "+InEqualityValues[(offer.FirstEquality-1)].Sym+" "):(" "+InEqualityValues[0].Sym+" ")} 
                    </label>)
                  :((aValue == '{C}')?
                    <label>
                      {(" "+(selectedOffer.ClientName)+"  ")} </label>
                      :(" "+aValue+" ")))
                      )}</li>
                      
                      ))}</ul>
              </Modal.Body>
              <Modal.Footer>
              {/* <Button variant="primary" onClick={handleOfferDetail}>
                  Delete
              </Button> */}
              {/* <Button variant="primary" onClick={handleCancelOfferDetail}>
                  Save Changes
              </Button>
              <Button variant="secondary" onClick={handleCancelOfferDetail}>
                  Cancel
              </Button> */}
              </Modal.Footer>
          </Modal>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={filteredOffers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </CardActions>
      </Card>
    </div>
  );
};

AdminOfferView.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
};
export default withAuthorization(AdminOfferView);
