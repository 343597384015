import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, IconButton, Button } from "@mui/material";
import WebService from "../../api/WebServices.js";
import { Link as RouterLink, withRouter } from "react-router-dom";

import {
  UserProfile,
  TotalDeposits,
  OfferActivty,
  ClaimRate,
  DepositsBySite,
  DepositHistory,
  ProductCard
} from "./components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import withAuthorization from "../../helpers/Authorization";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing()
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const KeywordDetails = props => {
  const classes = useStyles();
  const { history } = props;

  const [keywordDetails, setKeywordDetails] = useState([]);
  const [deleteKeyword, setDeleteKeyword] = useState(false);

  const keyword = props.location.state.user;

  var RequestObject = {
    IntTag: keyword.Id
  };

  const theme = useTheme();

  const handleBack = () => {
    history.goBack();
  };

  const fetchData = async () => {
    const result = await WebService.GetMyKeywordDetails(history, RequestObject);
    if(result != null){
      setKeywordDetails(result);
    }else{
      history.push({ pathname: "./sign-in" });
    }
  };

  const handleDeleteKeyword = keyword => {
    setDeleteKeyword(true);
  };

  const deleteKeywordCall = async () => {
    const result = await WebService.RemoveKeyword(history, RequestObject);
    if(result != null){
      history.push({ pathname: "./Transactions" });
    }else{
      history.push({ pathname: "./sign-in" });
    }
  };

  useEffect(() => {
    if (deleteKeyword) {
      deleteKeywordCall();
    }
  }, [deleteKeyword]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentHeader}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>

        <span className={classes.spacer} />

        
        <span className={classes.spacer} /> 
        <span className={classes.spacer} /> 
        <span className={classes.spacer} /> 
        <span className={classes.spacer} /> 
        <span className={classes.spacer} />
        <span className={classes.spacer} /> 
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={event => handleDeleteKeyword(keyword)}
        >
          Delete Keyword
        </Button>
      </div>

      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}></Grid>

        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <OfferActivty userDetails={keywordDetails} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ClaimRate userDetails={keywordDetails} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalDeposits userDetails={keywordDetails} />
        </Grid>

        <Grid item lg={12} md={12} xl={12} xs={12}>
          <DepositHistory userDetails={keywordDetails} />
        </Grid>
      </Grid>
    </div>
  );
};

KeywordDetails.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
};
export default withAuthorization(KeywordDetails);
