import React, { useState } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from "@mui/material";

import { GetMyOfferDetails } from "api/WebServices";
import { 
  GetBannerId,
  GetBannerName,
  GetExternalLink, 
  GetDateCreated,
  GetImageURL,
  GetBannerOfferId,
  GetBannerOrder,
  GetBannerIsActive,
  GetBannerIsActiveText,
  GetDeepLinkName
} from '../../../../helpers/BannerHelper'
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  imageContainer: {
    height: 120,
    width: 100,
    margin: "auto",
    marginBottom: "20px",
    borderRadius: "1px",
    overflow: "hidden",
    display: "flex",
    alignItems: "normal",
    justifyContent: "center",
  },
  imageBanner: {
    width: "100%",
    objectFit : "contain"
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const BannersTable = props => {
  const { className, Banners, history, Banner, ...rest } = props;

  const classes = useStyles();

  const selectedBanner = useState();

  const [selectedBanners, setSelectedBanners] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { Banners } = props;

    let selectedBanners;

    if (event.target.checked) {
      selectedBanners = Banners.map(Banner => Banner.Id);
    } else {
      selectedBanners = [];
    }

    setSelectedBanners(selectedBanners);
  };

  const handleSelectedBanner = (event, Banners) => {
    
    history.push({ pathname: "./AdminDetails", state: { Banner: Banners} });
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBanners.indexOf(id);
    let newSelectedBanners = [];

    if (selectedIndex === -1) {
      newSelectedBanners = newSelectedBanners.concat(selectedBanners, id);
    } else if (selectedIndex === 0) {
      newSelectedBanners = newSelectedBanners.concat(selectedBanners.slice(1));
    } else if (selectedIndex === selectedBanners.length - 1) {
      newSelectedBanners = newSelectedBanners.concat(selectedBanners.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBanners = newSelectedBanners.concat(
        selectedBanners.slice(0, selectedIndex),
        selectedBanners.slice(selectedIndex + 1)
      );
    }

    setSelectedBanners(newSelectedBanners);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Preview</TableCell>
                  <TableCell>Deep Link Type</TableCell>
                  <TableCell>Offer Id</TableCell>
                  <TableCell>Order</TableCell>
                  <TableCell>Active</TableCell> 
                </TableRow>
              </TableHead>
              <TableBody>
                {Banners.slice(0, rowsPerPage).map(Banner => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={GetBannerId(Banner)}
                    onClick={() => props.openEditBannerPopup(Banner)}
                    selected={selectedBanners.indexOf(GetBannerId(Banner)) !== -1}
                  >
                    <TableCell>
                        {" "}
                        <div className={classes.imageContainer}>
                          <img
                            alt="Product"
                            className={classes.imageBanner}
                            
                            src={GetImageURL(Banner)}
                          />
                        </div>
                      </TableCell>
                    <TableCell>{GetDeepLinkName(Banner.DeepLink)}</TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{GetBannerOfferId(Banner)}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{GetBannerOrder(Banner)}</TableCell>
                    <TableCell>{GetBannerIsActiveText(Banner)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={Banners.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

BannersTable.propTypes = {
  className: PropTypes.string,
  Banners: PropTypes.array.isRequired,
  history: PropTypes.object,
  Banner: PropTypes.object
};

export default withMasterAuthorization(BannersTable);
