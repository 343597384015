import moment from "moment";



export const DL_NONE = "None"
export const DL_REFERRAL = "Referral"
export const DL_SETTINGS = "Settings"
export const DL_BANK = "Bank Accounts"
export const DL_OFFER = "Offer Details"
export const DL_EXTERNAL = "External Link"

export const DL_NONE_INT = 0
export const DL_REFERRAL_INT = 1
export const DL_SETTINGS_INT = 2
export const DL_BANK_INT = 3
export const DL_OFFER_INT = 4
export const DL_EXTERNAL_INT = 5

export function GetDeepLinkName(logTypeInt){
  switch(logTypeInt){
    case DL_NONE_INT:
      return DL_NONE;
    case DL_REFERRAL_INT:
      return DL_REFERRAL;
    case DL_SETTINGS_INT:
      return DL_SETTINGS;
    case DL_BANK_INT:
      return DL_BANK;
    case DL_OFFER_INT:
      return DL_OFFER;
    case DL_EXTERNAL_INT:
      return DL_EXTERNAL;
  }
  return DL_NONE;
}

export function GetBannerId(banner){ 
  return banner.Id !== null ? banner.Id : 0;
}

/**
 * @return {string}
 */
export function GetBannerName(banner){
  if(banner.Name){
    return banner.Name;
  }else{ 
    return "N/A";
  }
}

/**
 * @return {string}
 */
export function GetBannerDescription(banner){
  if(banner.Description){
    return banner.Description;
  }else{ 
    return "N/A";
  }
}

export function GetImageURL(banner){ 
  return banner.ImageUrl !== null ? banner.ImageUrl : "/assets/betfully-icon.png";
}

export function GetImageId(banner){ 
  return banner.StoredImageId !== null ? banner.StoredImageId : 0;
}

export function GetBannerDeepLink(banner){ 
  return banner.DeepLink !== null ? banner.DeepLink : 0;
}

export function GetBannerTarget(banner){ 
  return banner.Target !== null ? banner.Target : 0;
}

export function GetBannerOfferId(banner){ 
  return banner.OfferId !== null ? banner.OfferId : 0;
}

export function GetBannerIsActive(banner){ 
  return banner.IsActive !== null ? banner.IsActive : false;
}

export function GetBannerIsActiveText(banner){ 
  return GetBannerIsActive(banner) ? 'Yes' : 'No';
}

export function GetBannerOrder(banner){ 
  return banner.Order !== null ? banner.Order : 0;
}

/**
 * @return {string}
 */
export function GetExternalLink(banner) {
  if (banner.ExternalLink) {
    return banner.ExternalLink;
  } else {
    return "N/A";
  }
}

/**
 * @return {string}
 */
export function GetDateCreated(banner) {
  return banner.DateCreated !== null ? moment(banner.DateCreated).format("MMM DD, YYYY"): "N/A"; 
}