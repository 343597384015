export const MaxDepositValues = [0, 100, 500, 1000, 5000, 10000, "custom"];
export const MinDepositValues = [0, 1, 5, 10, 25, 50, 100, 500, "custom"];

export const CashBackValues = [1, 2, 5, 10, "custom"];
export const MaxDaysValues = [3, 7, 15, 30, 90, "custom"];
export const InEqualityValues = 
  [
    {Sym:'=', Val:1},
    {Sym:'<', Val:2},
    {Sym:'≤', Val:3},
    {Sym:'>', Val:4},
    {Sym:'≥', Val:5}]
export const MaxTransactionValues = [0, 1, 3, 5, 10, 100, "custom"];
export const OfferTransactionTypeValues = ["-","Deposit", "Withdraw", "Purchase", "Subscription", "Registration"]
export const UserActionTypeValues = [
  "Deposit Funds Into Operator Account",
  "Withdraw from ATM", 
  "Open Account, Deposit Funds", 
  "Open Account, Deposit Funds, Place Wager",
  "Watch Instant Ad",
  "Offer Wall"
]

export const RewardTypeValues = [
  "Instant Reward",
  "Percentage Back",
  "Ad Reward"
]

export function GetRewardTypeFromOfferType(offertype){
  switch (offertype) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      return 0;
    case 12:
    case 13:
    case 14:
    case 15:
      return 1;
    case 4:
    case 5:
      return 2;
    case 16:
    case 17:
    case 18:
    case 19:
      return 0
    default:
      return 0;
  }
}

export function GetActionTypeFromOfferType(offertype){
 
    switch (offertype) {
      case 12:
      case 16:
        return 0;
      case 13:
      case 17:
        return 1;
      case 14:
      case 18:
        return 2;
      case 15:
      case 19:
        return 3;
      case 4:
        return 5;
      case 5:
        return 4;
      case 0:
      case 1:
      case 2:
      case 3:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      default:
        return 0;
  }
}

export function GetPointsFromDollars(dollarValue){
  return dollarValue*100;
}

export function GetDollarsFromPoints(pointValue){
  return (pointValue/100).toFixed(2);
}

export const EXPIRED_TOKEN = 401;
