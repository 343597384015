import moment from "moment";
import { NumericFormat } from 'react-number-format';
import React from "react";
import SessionHelper from "helpers/SessionHelper";

//The settled dollar value. Positive values when money moves out of the account; 
//negative values when money moves in. For example, purchases are positive; 
//credit card payments, direct deposits, refunds are negative.
export function GetPlaidDepositTotal(transaction){
  if(transaction.PlaidDepositTotal){
    return transaction.PlaidDepositTotal
  } else{
    return 0
  }
}

export function GetPlaidWithdrawTotal(transaction){
  if(transaction.PlaidWithdrawTotal){
    return (-1)*transaction.PlaidWithdrawTotal
  } else{
    return 0
  }
}

export function GetPlaidTotalFormatted(transaction){
  if(transaction.PlaidTotal){
    return <NumericFormat decimalScale={2} fixedDecimalScale={true} value={transaction.PlaidTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} />
  } else{
    return <NumericFormat decimalScale={2} fixedDecimalScale={true} value={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
  }
}
//For pending transactions, Plaid returns the date the transaction occurred; for posted transactions, 
//Plaid returns the date the transaction posts.Both dates are returned in an ISO 8601 format (YYYY-MM-DD).
export function GetPlaidDateString(transaction){
  if (transaction.PlaidDate) {
    return transaction.PlaidDate;
  } else {
    return "";
  }
}

export function GetPlaidDate(transaction) {
  if (transaction.PlaidDate) {
    return moment(transaction.PlaidDate, "YYYY-MM-DD").format("MM/DD/YY");
  } else {
    return "";
  }
}

export function GetAmountProcessed(transaction){
  if(transaction.AmountProcessed){
    return transaction.AmountProcessed
  } else{
    return 0
  }
}

export function GetPointsAwarded(transaction){
  if(transaction.PointsAwarded){
    return transaction.PointsAwarded
  } else{
    return 0
  }
}



export function GetCompanyId(transaction){
  if(transaction.CompanyId){
    return transaction.CompanyId
  } else{
    return 0
  }
}

export function GetCompanyName(transaction){
  var name = "n/a"
  const companyList = SessionHelper.getCompanyList();
  if(companyList !== undefined && companyList != null){
    const found = companyList.find(element => element.Id == GetCompanyId(transaction));
    if(found){
      name = found.Name;
    }
  }
  return name;
}


export function GetClientName(clientId){
  var name = "n/a"
  const companyList = SessionHelper.getCompanyList();
  if(companyList !== undefined && companyList != null){
    const found = companyList.find(element => element.Id == clientId);
    if(found){
      name = found.Name;
    }
  }
  return name;
}
