import moment from "moment";

export function GetId(payoutRequest){ 
  return payoutRequest.Id !== null ? payoutRequest.Id : "N/A";
}

export function GetReceiverEmailAddress(payoutRequest){
  
  return payoutRequest.EmailToReceivePayment !== null ? payoutRequest.EmailToReceivePayment : "N/A";
}

export function GetPayoutAmount(payoutRequest){
  return payoutRequest.AmountToBePaidOut !== null ? "$" + payoutRequest.AmountToBePaidOut : "N/A";
}

export function GetFirebaseUserId(payoutRequest){ 
  return payoutRequest.FirebaseUserId !== null ? payoutRequest.FirebaseUserId : "N/A";
}

export function GetStatus(payoutRequest){
  switch(payoutRequest.Status){
    case 0:
      return "Pending Approval"
    case 1:
      return "Completed"
    case 2: 
      return "Refunded";
    case 3:
      return "Rejected";
  }
}

export function GetStatusAsInt(payoutStatus){
  switch (payoutStatus) {
    case PayoutStatus.Pending:
      return 0;
    case PayoutStatus.Completed:
      return 1;
    case PayoutStatus.Refunded:
      return 2;
    case PayoutStatus.Rejected:
      return 3;
  }
}

export function GetDateStatusWasUpdated(payoutRequest){ 
  return payoutRequest.DateStatusUpdated !== null ? moment(payoutRequest.DateStatusUpdated).format('MMMM Do YYYY, h:mm:ss a') : "N/A";
}

/**
 * @return {string}
 */
export function GetDateCreated(payoutRequest) {
  return payoutRequest.DateCreated !== null ? moment(payoutRequest.DateCreated).format('MMMM Do YYYY, h:mm:ss a') : "N/A";
}

export function GetWebUser(log){
  if(log.WebUser){
    return log.WebUser;
  }else{
    return null;
  }
}

export const PayoutStatus = {
  Pending: "Pending",
  Completed: "Completed",
  Refunded: "Refunded",
  Rejected: "Rejected",
};