import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout, Basic as BasicLayout} from "./layouts";

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  ProductList as ProductListView,
  AdminList as AdminListView,
  CompanyList as CompanyListView,
  BannerList as BannerListView,
  StoredImageList as StoredImageListView,
  SignIn as SignInView,
  UserDetails as UserDetailsView,
  CreateOffer as CreateOfferView,
  CreateAdminUser as CreateAdminView,
  UserPayoutList as UserPayoutListView,
  FraudShield as FraudShieldView,
  MasterKeywordList as MasterKeywordListView,
  PaypalWebhookResponseList as PaypalWebhookListView,
  PaypalWebhookDetails as PaypalWebhookDetailsView,
  OfferDetails as OfferDetailsView,
  KeywordDetails as KeywordDetailsView,
  EditOffer as EditOfferView,
  EmailConfirmed as EmailConfirmedView,
  ResetPassword as ResetPasswordView,
  SBResetPassword as SBResetPasswordView,
  ResetPasswordRequest as ResetPasswordRequestView,
  CompanyKeywordList as CompanyKeywordListView,
  UserActivitySearch as UserActivitySearchView,
  UserActivity as UserActivityView,
  PropBets as PropBetsView,
  GameOdds as GameOddsView,
  FutureOdds as FutureOdds,
  MyRedirect as MyRedirectView,
  Settings as SettingsView,
  WildfireAdmin as WildfireAdminView,
   AdminOffersView, 
   UnauthorizedAccess
} from "./views";

const Routes = () => {
   const GetMasterRoutesIfAuthorized = () => {
      
   }
   
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />

      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/offers"
      />

      <RouteWithLayout
        component={AdminListView}
        exact
        layout={MainLayout}
        path="/Admins"
      />
      <RouteWithLayout
        component={CompanyListView}
        exact
        layout={MainLayout}
        path="/Companies"
      />
     <RouteWithLayout
        component={WildfireAdminView}
        exact
        layout={MainLayout}
        path="/Wildfire"
      />
      <RouteWithLayout
        component={BannerListView}
        exact
        layout={MainLayout}
        path="/Banners"
      />
      <RouteWithLayout
        component={StoredImageListView}
        exact
        layout={MainLayout}
        path="/StoredImages"
      />
      <RouteWithLayout
        component={CreateAdminView}
        exact
        layout={MainLayout}
        path="/CreateAdminUser"
      />

      <RouteWithLayout
        component={FraudShieldView}
        exact
        layout={MainLayout}
        path="/FraudShieldView"
      />

      <RouteWithLayout
        component={UserPayoutListView}
        exact
        layout={MainLayout}
        path="/UserPayouts"
      />

      <RouteWithLayout
        component={MasterKeywordListView}
        exact
        layout={MainLayout}
        path="/MasterKeyword"
      />

      <RouteWithLayout
        component={PaypalWebhookListView}
        exact
        layout={MainLayout}
        path="/PaypalWebhooks"
      />

      <RouteWithLayout
        component={PaypalWebhookDetailsView}
        exact
        layout={MainLayout}
        path="/PaypalWebhookDetails"
      />
      
      <RouteWithLayout
        component={UserDetailsView}
        exact
        layout={MainLayout}
        path="/userDetails"
      />
      
      <RouteWithLayout
        component={CreateOfferView}
        exact
        layout={MainLayout}
        path="/CreateOffer"
      />
      <RouteWithLayout
        component={OfferDetailsView}
        exact
        layout={MainLayout}
        path="/OfferDetails"
      />
      <RouteWithLayout
        component={KeywordDetailsView}
        exact
        layout={MainLayout}
        path="/KeywordDetails"
      />
      <RouteWithLayout
        component={EditOfferView}
        exact
        layout={MainLayout}
        path="/EditOffer"
      />

      <Route 
         component={EmailConfirmedView} 
         exact path="/EmailConfirmed"
      />

      <Route 
         component={ResetPasswordView} 
         exact path="/ResetPassword" 
      />

      <Route 
         component={SBResetPasswordView} 
         exact path="/SBResetPassword" 
      />



      <Route
        component={ResetPasswordRequestView}
        exact
        path="/RequestPasswordReset"
      />

      <RouteWithLayout
        component={CompanyKeywordListView}
        exact
        layout={MainLayout}
        path="/Keywords"
      />

      <RouteWithLayout
        component={UserActivitySearchView}
        exact
        layout={MainLayout}
        path="/UserActivitySearch"
      />

      <RouteWithLayout
        component={UserActivityView}
        exact
        layout={MainLayout}
        path="/UserActivity"
      />

        <RouteWithLayout 
            component={PropBetsView} 
            exact 
            layout={BasicLayout}
            path="/PropBets"/>

        <RouteWithLayout 
            component={GameOddsView} 
            exact 
            layout={BasicLayout}
            path="/GameOdds"/>  

<RouteWithLayout 
            component={MyRedirectView} 
            exact 
            layout={BasicLayout}
            path="/MyRedirect"/>  

<RouteWithLayout 
            component={FutureOdds} 
            exact 
            layout={BasicLayout}
            path="/FutureOdds"/>  
        
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/Settings"
      />

      <RouteWithLayout
        component={AdminOffersView}
        exact
        layout={MainLayout}
        path="/AdminOffersView"
      />
       
      <RouteWithLayout 
         component={UnauthorizedAccess} 
         layout={MainLayout}
         exact
         path="/Unauthorized"
      />
      
    </Switch>
  );
};

export default Routes;
