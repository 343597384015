import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import StorageServices from "../../api/StorageServices.js";
import PropTypes from "prop-types";
import BannersTable from './Components/BannersTable'
import BannersToolbar from './Components/BannersToolbar'
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import { storage } from "../../firebase";
import { BANNER } from "../../helpers/StoredImageHelper";
import ImagePreviewGrid from '../StoredImageList/Components/ImagePreviewGrid'
import SessionHelper from 'helpers/SessionHelper'

import { 
    DL_NONE,
    DL_REFERRAL,
    DL_SETTINGS,
    DL_BANK,
    DL_OFFER,
    DL_EXTERNAL,
    DL_NONE_INT,
    DL_REFERRAL_INT,
    DL_SETTINGS_INT,
    DL_BANK_INT,
    DL_OFFER_INT,
    DL_EXTERNAL_INT,
    GetBannerId,
    GetBannerOfferId,
    GetImageId,
    GetImageURL,
    GetBannerOrder,
    GetBannerIsActive,
    GetExternalLink,
    GetBannerDeepLink,
    GetDeepLinkName
  } from '../../helpers/BannerHelper'

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import {
    Button,
  } from "reactstrap";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    imageContainer: {
        height: 100,
        width: 300,
        margin: "0 auto",
        marginBottom: "20px",
        borderRadius: "1px",
        overflow: "hidden",
        display: "flex",
        alignItems: "normal",
        justifyContent: "center",
      },
      image: {
        width: "100%",
        objectFit : "contain"
      }
}));

const BannerList = props => {
    const { history, ...rest } = props;
    const classes = useStyles();
    const [banners, setBanners] = useState([]);

    const [showEditBanner, setShowEditBanner] = useState(false);
    const handleCloseEditBanner = () => setShowEditBanner(false);
    const handleShowEditBanner = (theBanner) => {
        console.log("The Banner: " + theBanner.Name);
        setBannerIdInput(GetBannerId(theBanner));
        setBannerOfferIdInput(GetBannerOfferId(theBanner));
        setBannerImgUrlInput(GetImageURL(theBanner));
        setBannerImgIdInput(GetImageId(theBanner))
        setBannerIsActiveInput(GetBannerIsActive(theBanner));
        setBannerOrderInput(GetBannerOrder(theBanner));
        setBannerDeepLinkInput(GetBannerDeepLink(theBanner));
        setBannerExternalLinkInput(GetExternalLink(theBanner));
        setShowEditBanner(true);
    }

    const [BannerOfferIdInput, setBannerOfferIdInput] = useState(0);
    const [BannerIdInput, setBannerIdInput] = useState(0);
    const [BannerImgUrlInput, setBannerImgUrlInput] = useState("");
    const [BannerImgIdInput, setBannerImgIdInput] = useState(0);
    const [BannerIsActiveInput, setBannerIsActiveInput] = useState(true);
    const [BannerOrderInput, setBannerOrderInput] = useState(0);
    const [BannerDeepLinkInput, setBannerDeepLinkInput] = useState(0);
    const [BannerExternalLinkInput, setBannerExternalLinkInput] = useState("");

    const [picturesInput, setPictures] = useState([]);

    const bannerOfferIdString = 'bannerOfferIdInput';
    const bannerImageUrlString = 'bannerImageUrlInput';
    const bannerIsActiveString = 'bannerIsActiveInput';
    const bannerOrderString = 'bannerOrderInput';
    const bannerDeepLinkString = 'bannerDeepLinkInput';
    const bannerExternalLinkString = 'bannerExternalLinkInput';

    const onDrop = (picture, something) => {
        console.log("Image: "+picture);

        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = something;
        //Validate the File Height and Width.
        image.onload = function () {
            var height = this.height;
            var width = this.width;

            

            if (height/width < 0.25 || height/width > 0.45) {
            alert("Recommended Image ratio is 1:3");
            return false;
            }
            return true;
        };
        setPictures(
            picturesInput.concat(picture)
        );
    }

    const [showAddBanner, setShowAddBanner] = useState(false);
    const handleCloseAddBanner = () => setShowAddBanner(false);
    const handleShowAddBanner = () => {
        
        setShowAddBanner(true);
        setBannerOfferIdInput(0);
        setBannerIdInput(0);
        setBannerImgUrlInput("");
        setBannerImgIdInput(0)
        setBannerIsActiveInput(true);
        setBannerOrderInput(0);
        setBannerDeepLinkInput(0);
        setBannerExternalLinkInput("");
    }

    const handleCreateBanner = () => {
        console.log("Send Banner")
        console.log("Banner request:");
        console.log(BannerOfferIdInput);
            console.log("Create Banner Param ImgUrl: "+BannerImgUrlInput);
            console.log("Create Banner Param ImgId: "+BannerImgIdInput);
          WebService.CreateBanner(history, BannerOrderInput, BannerOfferIdInput, 
            BannerIsActiveInput, BannerImgUrlInput, BannerImgIdInput, BannerDeepLinkInput, BannerExternalLinkInput)
            .then(data => {
              console.log("ABC DONE");
              console.log("Data: "+data);
              if(data != null) {
                // Temporary sorting solution:
                data.sort((firstBanner, secondBanner) =>
                       (GetBannerId(firstBanner) != null ? GetBannerId(firstBanner) : 0) -
                       (GetBannerId(secondBanner) != null ? GetBannerId(secondBanner) : 0));
                    setBanners(data)
                handleCloseAddBanner();
              }else{
                handleCloseAddBanner()
              }
            });
    }

    const handleEditBanner = () => {
        WebService.ModifyBanner(history, BannerIdInput, BannerOrderInput, BannerOfferIdInput, 
        BannerIsActiveInput, BannerImgUrlInput, BannerImgIdInput, BannerDeepLinkInput, BannerExternalLinkInput)
        .then(data => {
            console.log("ABC DONE");
            console.log("Data: "+data);
            if(data != null) {
                
                handleCloseEditBanner();
                // Temporary sorting solution:
                data.sort((firstBanner, secondBanner) =>
                (GetBannerId(firstBanner) != null ? GetBannerId(firstBanner) : 0) -
                (GetBannerId(secondBanner) != null ? GetBannerId(secondBanner) : 0));
                setBanners(data)
            }else{
                handleCloseEditBanner()
            }
        });
    }

    const handleDeleteBanner = () => {
        WebService.DeleteBanner(history, BannerIdInput)
        .then(data => {
            if(data != null) {
                
                handleCloseEditBanner();
                // Temporary sorting solution:
                data.sort((firstBanner, secondBanner) =>
                (GetBannerId(firstBanner) != null ? GetBannerId(firstBanner) : 0) -
                (GetBannerId(secondBanner) != null ? GetBannerId(secondBanner) : 0));
                setBanners(data)
            }else{
                handleCloseEditBanner()
            }
        });
    }



    const myChangeHandler = (event) => {
        console.log("Name:"+event.target.name);
        console.log("Value:"+event.target.value);
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === bannerOfferIdString){
            setBannerOfferIdInput(val);
        }
        /*else if(nam === bannerImageUrlString){
            setBannerImgUrlInput(val);
        }*/else if(nam === bannerIsActiveString){
            setBannerIsActiveInput(event.target.checked);
        }else if(nam === bannerOrderString){
            setBannerOrderInput(val);
        }else if(nam === bannerDeepLinkString){
           // setBannerDeepLinkInput(val)
        }else if(nam === bannerExternalLinkString){
            setBannerExternalLinkInput(val)
        }
        
    }

    const onDeepLinkSelected = (value) => {
        setBannerDeepLinkInput(value)
    };

    const deepLinkOptions = [ 
        DL_NONE_INT,
        DL_REFERRAL_INT,
        DL_SETTINGS_INT,
        DL_BANK_INT,
        DL_OFFER_INT,
        DL_EXTERNAL_INT
      ];

    const [showViewStoredImages, setShowViewStoredImages] = useState(false);
    const handleCloseViewStoredImages = () => setShowViewStoredImages(false);
    const handleShowViewStoredImages = () => {
        setShowViewStoredImages(true);
    }
    const onImageSelected = (imageId, imageUrl) => {
        console.log("Image selected Id: "+ imageId)
        setBannerImgUrlInput(imageUrl);
        setBannerImgIdInput(imageId)
        handleCloseViewStoredImages();
    }

    useEffect(() => {
        if(SessionHelper.isMasterAdmin()) {
            const fetchData = async () => {
                const result = await WebService.GetAllBanners(history, undefined);
                if (result != null) {
                    // Temporary sorting solution:
                    result.sort((firstBanner, secondBanner) =>
                       (GetBannerId(firstBanner) != null ? GetBannerId(firstBanner) : 0) -
                       (GetBannerId(secondBanner) != null ? GetBannerId(secondBanner) : 0));
                    setBanners(result)
                } else {
                    history.push({pathname: "./sign-in"});
                }
            };
            fetchData();
        }
    }, []);

    return (
      <div className={classes.root}>
          <ImagePreviewGrid 
          isBanner = {true}
          showViewStoredImages={showViewStoredImages} 
          handleCloseViewStoredImages={handleCloseViewStoredImages}
          onImageSelected={onImageSelected}
          companyId={0}
          imageType={2}/>
        <Modal
            className={classes.content}
            show={showAddBanner}
            onHide={handleCloseAddBanner}
            centered>
            <Modal.Header closeButton>
            <Modal.Title>{"Add Banner"}</Modal.Title>
            </Modal.Header>
            <Modal.Body><div>{"Banner You Want to Add"}</div>
            
            <Form >
                <Form.Group controlId="formBasicEmail" >
                   
                    {/* <Form.Label>Image Url</Form.Label>
                    <Form.Control required placeholder="Image Url For Banner to Display" name={bannerImageUrlString} onChange={myChangeHandler}/> */}
                    <Form.Label>Active?</Form.Label>
                    <Form.Check required placeholder="Is the Banner Active" name={bannerIsActiveString} onChange={myChangeHandler}/>
                    <Form.Label>Order Priority</Form.Label>
                    <Form.Control required placeholder="0 is default" value={BannerOrderInput} type="number" name={bannerOrderString} onChange={myChangeHandler}/>
                    <Form.Text className="text-muted">
                    
                    </Form.Text>
                    <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                    Mobile App Deep Link
                </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPref"
                        onChange = {(event) => onDeepLinkSelected(event.target.value)}
                    >
                        {/* <option value={0}>None</option> */}
                        {deepLinkOptions.map((aNum) => (
                        <option value={aNum} key={aNum}>{GetDeepLinkName(aNum)}</option>
                        ))}
                    </Form.Control>
                    {BannerDeepLinkInput ==  DL_OFFER_INT && <Form.Label>Input the Offer Id for the Banner</Form.Label>}
                    {BannerDeepLinkInput ==  DL_OFFER_INT && <Form.Control required placeholder="Enter OfferId" type="number" name={bannerOfferIdString}  onChange={myChangeHandler}/>}
                    {/* <Form.Control required placeholder="Enter BannerDeepLink" type="number" name={bannerDeepLinkString}  onChange={myChangeHandler}/> */}
                    {BannerDeepLinkInput ==  DL_EXTERNAL_INT && <Form.Label>External Link</Form.Label>}
                    {BannerDeepLinkInput ==  DL_EXTERNAL_INT && <Form.Control required placeholder="Url For Banner to Redirect to" name={bannerExternalLinkString} onChange={myChangeHandler}/>}
                </Form.Group>
                </Form>
                <div className={classes.imageContainer}>
                          <img
                            className={classes.image}
                            onClick={() => handleShowViewStoredImages()}
                            src={BannerImgUrlInput}
                            alt="banner image here"
                            loading="lazy"
                          />
                        </div>
                </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCreateBanner}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleCloseAddBanner}>
                Cancel
            </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            className={classes.content}
            show={showEditBanner}
            onHide={handleCloseEditBanner}
            centered>
            <Modal.Header closeButton>
            <Modal.Title>{"Edit Banner"}</Modal.Title>
            </Modal.Header>
            <Modal.Body><div>{"Banner You Want to Modify"}</div>
            <div className={classes.imageContainer}>
                          <img
                            className={classes.image}
                            onClick={() => handleShowViewStoredImages()}
                            src={BannerImgUrlInput}
                            alt={"holder.js/100px250"}
                            loading="lazy"
                          />
                        </div>
                        <Image src="holder.js/100px250" fluid />
            <Form >
                <Form.Group controlId="formBasicEmail" >
                    <Form.Label>Active?</Form.Label>
                    <Form.Check required placeholder="Is the Banner Active" name={bannerIsActiveString} defaultChecked={BannerIsActiveInput} onChange={myChangeHandler}/>
                    <Form.Label>Order Priority</Form.Label>
                    <Form.Control required placeholder="0 is default" type="number" name={bannerOrderString} value={BannerOrderInput} onChange={myChangeHandler}/>
                    <Form.Text className="text-muted">
                    </Form.Text>
                    <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                        Mobile App Deep Link
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPref"
                        onChange = {(event) => onDeepLinkSelected(event.target.value)}
                        value={BannerDeepLinkInput}
                    >
                        {/* <option value={0}>None</option> */}
                        {deepLinkOptions.map((aNum) => (
                        <option value={aNum} key={aNum}>{GetDeepLinkName(aNum)}</option>
                        ))}
                    </Form.Control>
                    {/* <Form.Label>Deep Link Type</Form.Label> */}
                    {/* <Form.Control required placeholder="Enter BannerDeepLink" type="number" name={bannerDeepLinkString} value={BannerDeepLinkInput} onChange={myChangeHandler}/> */}
                    {BannerDeepLinkInput ==  DL_OFFER_INT && <Form.Label>Input the Offer Id for the Banner</Form.Label>}
                    {BannerDeepLinkInput ==  DL_OFFER_INT && <Form.Control required placeholder="Enter OfferId" type="number" defaultValue={BannerOfferIdInput} name={bannerOfferIdString}  onChange={myChangeHandler}/>}
                    {/* <Form.Control required placeholder="Enter BannerDeepLink" type="number" name={bannerDeepLinkString}  onChange={myChangeHandler}/> */}
                    {BannerDeepLinkInput ==  DL_EXTERNAL_INT && <Form.Label>External Link</Form.Label>}
                    {BannerDeepLinkInput ==  DL_EXTERNAL_INT && <Form.Control required placeholder="Url For Banner to Redirect to" defaultValue={BannerExternalLinkInput} name={bannerExternalLinkString} onChange={myChangeHandler}/>}


                    {/* <Form.Label>Input the Offer Id for the Banner</Form.Label>
                    <Form.Control required placeholder="Enter OfferId" type="number" name={bannerOfferIdString} value={BannerOfferIdInput} onChange={myChangeHandler}/> */}
                    {/* <Form.Label>Redirect Url</Form.Label>
                    <Form.Control required placeholder="Image Url For Banner to Display" name={bannerImageUrlString} value={BannerImgUrlInput} onChange={myChangeHandler}/> */}
                    {/* <Form.Group controlId="formBasicCheckbox">
                    </Form.Group> */}
                    {/* <Form.Label>External Link</Form.Label>
                    <Form.Control required placeholder="Url For Banner to Redirect to" name={bannerExternalLinkString} value={BannerExternalLinkInput} onChange={myChangeHandler}/> */}
                </Form.Group>
                </Form>
               
                </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleEditBanner}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleCloseEditBanner}>
                Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteBanner}>
                Delete
            </Button>
            </Modal.Footer>
        </Modal>
        <BannersToolbar history={history} openAddBannerPopup={handleShowAddBanner}/>
        <div className={classes.content}>
            <BannersTable Banners={banners} openEditBannerPopup={handleShowEditBanner}/>
        </div>
      </div> 
    );
};

BannerList.propTypes = {
    history: PropTypes.object
};

export default withMasterAuthorization(BannerList);
