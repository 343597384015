import moment from "moment";

export function GetOfferLocationId(product) {
  if (product.Id) {
    return product.Id;
  } else {
    return 0;
  }
}

export function GetOfferModelId(product) {
  if (product.OfferItemId) {
    return product.OfferItemId;
  } else {
    return 0;
  }
}


export function GetProvinceId(product) {
  if (product.ProvinceId) {
    return product.ProvinceId;
  } else {
    return 0;
  }
}

export function GetDateCreated(product) {
  if (product.DateCreated) {
    return moment(product.DateCreated).format("MMM DD, YYYY");
  } else {
    return "N/A";
  }
}

