import React, { useEffect, useState } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button'
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Avatar,
  Tooltip,
  TableSortLabel
} from "@mui/material";

import {
  GetId,
  GetReceiverEmailAddress,
  GetPayoutAmount,
  GetFirebaseUserId,
  GetDateStatusWasUpdated,
  GetDateCreated,
  GetStatus,
  GetWebUser
} from "../../../../helpers/UserPayoutHelper";
import Checkbox from "@mui/material/Checkbox";
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {},
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const UserPayoutsTable = (props) => {

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);
  
  const sortTypeList = [
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.NUM,
    COLUMNS_TYPE.REV_NUM,
    COLUMNS_TYPE.NUM,
    COLUMNS_TYPE.REV_NUM,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.DATE,
    COLUMNS_TYPE.REV_DATE,
    COLUMNS_TYPE.DATE,
    COLUMNS_TYPE.REV_DATE
  ];
  const dataRetrieveFunctionList = [
    GetReceiverEmailAddress, 
    GetReceiverEmailAddress, 
    GetPayoutAmount, 
    GetPayoutAmount, 
    GetFirebaseUserId, 
    GetFirebaseUserId, 
    GetStatus,
    GetStatus,
    GetDateCreated,
    GetDateCreated,
    GetDateStatusWasUpdated,
    GetDateStatusWasUpdated
  ];

  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, UserPayouts, sortTypeList, dataRetrieveFunctionList, GetFirebaseUserId, undefined, setSelectedSort) 
  };

  const handleSelectedUser = (event, usera) => {
    history.push({ pathname: "./userdetails", state: { user: usera } });
  };

  const handleIsSortActive = (columnType) => {
    return isSortActive(columnType, selectedSort)
  };

  const {
    className,
    UserPayouts,
    AddToListOfSelectedPayouts,
    RemoveFromListOfSelectedPayouts,
    showSelectBox,
    history,
    ...rest
  } = props;
  const classes = useStyles();
  const [selectedUserPayouts, setSelectedUserPayouts] = useState([]);
  const [idOfSelectedUserPayouts, setIdOfSelectedUserPayouts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const createDictionaryOfPayoutIds = () => {
    UserPayouts.forEach(function (uPayout, index) {
      idOfSelectedUserPayouts.push({
        key: uPayout.Id,
        value: false,
      });
      setIdOfSelectedUserPayouts(idOfSelectedUserPayouts);
    });
  };

  const handleCheckboxClicked = (event, payoutRequest) => {
    event.stopPropagation();
    if (event.target.checked) {
      idOfSelectedUserPayouts[payoutRequest.Id] = true;
      AddToListOfSelectedPayouts(payoutRequest);
    } else {
      // Checkbox is being unchecked:
      idOfSelectedUserPayouts[payoutRequest.Id] = false;
      RemoveFromListOfSelectedPayouts(payoutRequest);
    }
  };

  const handleClickedUserPayout = (event, UserPayouts) => {
    event.stopPropagation();
    history.push({
      pathname: "./UserPayoutDetails",
      state: { UserPayout: UserPayouts },
    });
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                {UserPayouts !== undefined && (
                  <TableRow>
                    {showSelectBox && <TableCell>Select </TableCell>}
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL1_ASC) || handleIsSortActive(COLUMNS_SORT.COL1_DESC)}>
                          Email to receive payment
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL2_ASC) || handleIsSortActive(COLUMNS_SORT.COL2_DESC)}>
                          Payout amount
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL3_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL3_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL3_ASC) || handleIsSortActive(COLUMNS_SORT.COL3_DESC)}>
                          Firebase User Id
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL4_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL4_ASC) || handleIsSortActive(COLUMNS_SORT.COL4_DESC)}>
                          Status
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL5_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL5_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL5_ASC) || handleIsSortActive(COLUMNS_SORT.COL5_DESC)}>
                          Date Created
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL6_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL6_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL6_ASC) || handleIsSortActive(COLUMNS_SORT.COL6_DESC)}>
                          Date Status Changed
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                  
                )}
              </TableHead>
              <TableBody>
                {UserPayouts !== undefined &&
                  UserPayouts.slice((page)*rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((UserPayout) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={GetId(UserPayout)}
                      // onClick={event => handleClickedUserPayout(event, UserPayout)}
                      selected={
                        selectedUserPayouts.indexOf(GetId(UserPayout)) !== -1
                      }
                    >
                      {showSelectBox && (
                        <TableCell>
                          <Checkbox
                            onChange={(event) => {
                              handleCheckboxClicked(event, UserPayout);
                            }}
                            key={GetId(UserPayout)}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        {GetReceiverEmailAddress(UserPayout)}
                      </TableCell>
                      <TableCell>{GetPayoutAmount(UserPayout)}</TableCell>
                      <TableCell>{GetFirebaseUserId(UserPayout)}</TableCell>
                      <TableCell>{GetStatus(UserPayout)}</TableCell>
                      <TableCell>{GetDateCreated(UserPayout)}</TableCell>
                      <TableCell>
                        {GetDateStatusWasUpdated(UserPayout)}
                      </TableCell>
                      <TableCell>
                        <Button onClick={(event) => handleSelectedUser(event, GetWebUser(UserPayout))} color="link">DETAILS</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {/*}*/}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        {UserPayouts !== undefined && (
          <TablePagination
            component="div"
            count={UserPayouts.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
          />
        )}
      </CardActions>
    </Card>
  );
};

UserPayoutsTable.propTypes = {
  className: PropTypes.string,
  UserPayouts: PropTypes.array.isRequired,
  history: PropTypes.object,
  UserPayout: PropTypes.object,
};

export default withMasterAuthorization(UserPayoutsTable);
