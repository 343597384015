import React from "react";
import { withRouter } from "react-router-dom";
import webService from "../api/WebServices.js";
import withAuthorization from "./Authorization";


const withMasterAuthorization =  (Component) => {
   class WithMasterAuthorization extends React.Component {
      componentDidMount() {
         console.log("Master Authorization componentDidMount");
         // Not authorized:
         if(!this.isMasterAdmin()){
            if(!this.isAuthenticated()){
               console.log("Going to the sign in page:")
               this.props.history.push({ pathname: "./sign-in" }); 
            }else{
               console.log("Going to Unauthorized page:")
               this.props.history.push({ pathname: "./Unauthorized" })
            }
         }
      }


      render() {
         return <Component {...this.props} />;
      }

      isMasterAdmin = () => {
         var authorizationRole = webService.getUserAuthorization();
         console.log("Authorization Role value is: ");
         console.log(authorizationRole);
         return authorizationRole === "MasterAdmin";
      }

      isAuthenticated = () => {
         var authorizationRole = webService.getUserAuthorization();
         return authorizationRole === "Admin" ||
            authorizationRole === "Operator" ||
            authorizationRole === "Accounting" ||
            authorizationRole === "Director" ||
            authorizationRole === "Customer Service";
      }
      
   }

   return withRouter(WithMasterAuthorization);
};

export default withMasterAuthorization;