import React, { useState, useEffect } from "react";
import { Link , withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import WebService from "../../api/WebServices.js";
import SessionHelper from '../../helpers/SessionHelper';
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Facebook as FacebookIcon, Google as GoogleIcon } from "icons";
import Modal from "react-bootstrap/Modal";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%"
  },
  grid: {
    height: "100%"
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px"
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  centerWrapper: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center"
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: true,
    values: {},
    touched: {},
    errors: {}
  });
  const [showErrorPopupModal, setShowErrorPopupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popupData, setPopupData] = useState({
                                    title: "Error while logging in",
                                    body: "Pleases check your email or password"
  });
  
  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const [RequestObject, setRequestObject] = useState();

  useEffect(() => {
    SessionHelper.setDataRetrieved(false);
  }, []);

  useEffect(() => {
    if (RequestObject != null) {
      fetchSignInData();
    }
  }, [RequestObject]);

  const handleSignIn = event => {
    event.preventDefault();

    var user = {
      UserName: event.currentTarget.email.value,
      Email: event.currentTarget.email.value,
      Password: event.currentTarget.password.value
    };

    // Checking that all the values have valid input:
    if (user.UserName && user.Email && user.Password) {
      setRequestObject(user);
    }
  };

  //
  const fetchSignInData = () => {
    setIsLoading(true);
    WebService.SignIn(RequestObject).then(success => {
      if (success) {
        SessionHelper.setDataRetrieved(false);
        history.push("/Dashboard");
      }else{
        console.log("There was an error when signing in");
        setShowErrorPopupModal(true);
      }
      setIsLoading(false)
    });
  };
  
  const handleCloseErrorModal = () => {
    if(!isLoading){
      setShowErrorPopupModal(false);
    }
  }
  
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {(isLoading) && <Spinner animation="border" variant="primary" />}
      {!(isLoading) && 
        <div>
          <Modal
              className={classes.content}
              size="lg"
              show={showErrorPopupModal}
              onHide={handleCloseErrorModal}
              centered>
              <Modal.Header closeButton>
                <Modal.Title>{popupData.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{popupData.body}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleCloseErrorModal}>
                  Ok
                </Button>
              </Modal.Footer>
          </Modal>
          <Grid className={classes.grid} container>
            <Grid className={classes.content} item lg={12} xs={12}>
              <div className={classes.content}>
                <div className={classes.contentBody}>
                  <div className={classes.centerWrapper}>
                    <form className={classes.form} onSubmit={handleSignIn}>
                      <Typography className={classes.title} variant="h2">
                        Sign in
                      </Typography>
                      <TextField
                          className={classes.textField}
                          error={hasError("email")}
                          fullWidth
                          helperText={
                            hasError("email") ? formState.errors.email[0] : null
                          }
                          label="Email address"
                          name="email"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.email || ""}
                          variant="outlined"
                      />
                      <TextField
                          className={classes.textField}
                          error={hasError("password")}
                          fullWidth
                          helperText={
                            hasError("password") ? formState.errors.password[0] : null
                          }
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          type="password"
                          value={formState.values.password || ""}
                          variant="outlined"
                      />
                      <Button
                          className={classes.signInButton}
                          color="primary"
                          disabled={!formState.isValid}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                      >
                        Sign in now
                      </Button>
                      <div>
                        <Link to="/RequestPasswordReset">Reset Password</Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      }
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
