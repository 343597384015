import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import SessionHelper from "../../../../helpers/SessionHelper";
import StorageServices from "../../../../api/StorageServices.js";
import WebService from "../../../../api/WebServices.js";
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";
import Modal from 'react-bootstrap/Modal';
import ImageUploading from 'react-images-uploading';

import { 
  GetStoredImageId,
  GetImageURL,
  GetStoredImageName,
  GetStoredImageIsActive,
  GetStoredImageIsDefault,
  GetStoredImageClient,
  GetImageTypeName,
  BANNER,
  PROFILE,
  OFFER,
  OTHER,
  BANNER_INT,
  PROFILE_INT,
  OFFER_INT,
  OTHER_INT
} from '../../../../helpers/StoredImageHelper'

import {
  Button
} from "reactstrap";
import withAuthorization from "helpers/Authorization";
import { IndexLinkContainer } from "react-router-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  ImageList: {
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
    padding: 3
  },
  imageView: {
    width: "100%",
    height: "100%",
    objectFit : "contain"
  },
  imageContainer: {
    height: 64,
    width: 164,
    margin: "0 auto",
    marginBottom: "20px",
    borderRadius: "1px",
    overflow: "hidden",
    display: "flex",
    alignItems: "normal",
    justifyContent: "center",
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
const ImagePreviewGrid = props => {
  const classes = useStyles();
  const { className, history, companyId, imageType, onImageSelected, showViewStoredImages, handleCloseViewStoredImages, isBanner, ...rest } = props;

  const [showAddStoredImage, setShowAddStoredImage] = useState(false);
  const [picturesInput, setPictures] = useState([]);
  const [StoredImageImgUrlInput, setStoredImageImgUrlInput] = useState("");
  const [storedImages, setStoredImages] = useState([]);

  const handleCloseAddStoredImage = () => setShowAddStoredImage(false);
  const handleShowAddStoredImage = () => {
    setShowAddStoredImage(true);
    setPictures([]);
  }

  const uploadSuccess = (url, uploadedName) => {
    console.log("Inside upload Success: " + url);
    console.log(url);
    setStoredImageImgUrlInput(url);
    console.log(StoredImageImgUrlInput);
    handleCreateStoredImage(url,uploadedName);
  }

  const handleUploadCall = () => {
    StorageServices.handleUpload(picturesInput[0], companyId,  GetImageTypeName(imageType), uploadSuccess)
  }

  const onDrop = (imageList, addUpdateIndex) => {
    if(imageList !== null && imageList.length > addUpdateIndex){
        var img =  imageList[addUpdateIndex]

        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = img['data_url'];
        //Validate the File Height and Width.
        image.onload = function () {
            var height = this.height;
            var width = this.width;

            if(isBanner !== undefined && isBanner == true){
              if (height/width < 0.25 || height/width > 0.45) {
                alert("Recommended Image ratio is 1:3");
                return false;
                }
              }else{
              if (height/width > 1.1 || height/width < 0.9) {
                alert("Recommended Ratio for images is 1:1");
                return false;
              }
            }
            return true;
        };
        if(picturesInput.length > 0)
            picturesInput.pop()
        setPictures(
            picturesInput.concat(img)
        );
    console.log("Contents1: "+(img))
    console.log("Contents2: "+(img.file))
    console.log("Contents3: "+(img.file.name))
    // Object.keys(img.file).forEach(key => {
    //   console.log(`${key}: ${img.file[key]}`);
    // });
    }
    
}

//   const onDrop = (picture, something) => {
//     console.log("Image: "+picture);
//     //Initiate the JavaScript Image object.
//     var image = new Image();

//     //Set the Base64 string return from FileReader as source.
//     image.src = something;
//     //Validate the File Height and Width.
//     image.onload = function () {
//       var height = this.height;
//       var width = this.width;


//       if(isBanner !== undefined && isBanner == true){
//         if (height/width < 0.25 || height/width > 0.45) {
//           alert("Recommended Image ratio is 1:3");
//           return false;
//           }
//         }else{
//         if (height/width > 1.1 || height/width < 0.9) {
//           alert("Recommended Ratio for images is 1:1");
//           return false;
//         }
//       }
//       return true;
//     };
  
//     setPictures(
//         picturesInput.concat(picture)
//     );
// }

  const handleEditStoredImage = (storedImageId, clientId) => {
    console.log("Edit StoredImage")
    if(storedImageId != 0) {
      WebService.ModifyStoredImage(history, storedImageId, false, false, true, clientId)
        .then(data => {
          if(data != null) {
              // Temporary sorting solution:
              data.sort((firstStoredImage, secondStoredImage) =>
                  (GetStoredImageId(firstStoredImage) != null ? GetStoredImageId(firstStoredImage) : 0) -
                  (GetStoredImageId(secondStoredImage) != null ? GetStoredImageId(secondStoredImage) : 0));
              setStoredImages(data.filter(img => GetStoredImageIsActive(img)))
          }else{
          }
        });
    }
  }

  const handleCreateStoredImage = (storedImageUrl, uploadedName) => {
    console.log("StoredImage request:");
    console.log("Create StoredImage Param: "+storedImageUrl);
    const imageNameA = uploadedName;
    console.log(imageNameA);
    console.log(PROFILE_INT);
    console.log("CompanyId: "+companyId);
    WebService.CreateStoredImage(history, storedImageUrl, imageNameA, imageType, companyId, false, true)
    .then(data => {
        console.log("ABC DONE");
        console.log("Data: "+data);
        if(data != null) {
            // Temporary sorting solution:
            data.sort((firstStoredImage, secondStoredImage) =>
                (GetStoredImageId(firstStoredImage) != null ? GetStoredImageId(firstStoredImage) : 0) -
                (GetStoredImageId(secondStoredImage) != null ? GetStoredImageId(secondStoredImage) : 0));
            setStoredImages(data.filter(img => GetStoredImageIsActive(img)))
            handleCloseAddStoredImage();
        }else{
            StorageServices.handleDelete(companyId, imageNameA, GetImageTypeName(imageType), null)
            handleCloseAddStoredImage()
        }
    });
  }

  useEffect(() => {
    if(showViewStoredImages == true) {
      console.log("GetStoredImages Called")
        const fetchData = async () => {
            const result = await WebService.GetAllStoredImages(history, companyId, true, imageType);
            if (result != null) {
                // Temporary sorting solution:
                result.sort((firstStoredImage, secondStoredImage) =>
                   (GetStoredImageId(firstStoredImage) != null ? GetStoredImageId(firstStoredImage) : 0) -
                   (GetStoredImageId(secondStoredImage) != null ? GetStoredImageId(secondStoredImage) : 0));
                setStoredImages(result.filter(img => GetStoredImageIsActive(img)))
            } else {
                history.push({pathname: "./sign-in"});
            }
        };
        fetchData();
    }
  }, [showViewStoredImages]);

  const onLoadedImg = (img, tile) => {
    console.log("StoredImage request:");
     // 1. Make a shallow copy of the items
     let items = [...storedImages];

     const isLargeNumber = (element) => GetStoredImageId(element) == GetStoredImageId(tile);

     var index  = items.findIndex(isLargeNumber);
     if(index >= 0){
      // 2. Make a shallow copy of the item you want to mutate
      let item = {...items[index]};
      if(item.dimension === undefined){
        // 3. Replace the property you're intested in
        item.dimension = img.naturalWidth+"x"+img.naturalHeight;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        items[index] = item;
        // 5. Set the state to our new copy
        setStoredImages(items);
      }
     }
   //tile.dimension = img.naturalHeight+"x"+img.naturalWidth
  }
  return (
    <div className={classes.root}>
      <Modal
        className={classes.content}
        show={showViewStoredImages}
        onHide={handleCloseViewStoredImages}
        centered>
        <Modal.Header closeButton>
        <Modal.Title>{"Select an Image"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          <ImageList cellHeight={180} className={classes.ImageList}>
            <ImageListItem key="Subheader" cols={2} style={{ height: 'auto' }}>
            </ImageListItem>
            {storedImages.map((tile) => (
              <ImageListItem className={classes.imageContainer} key={GetStoredImageId(tile)}>
                <img onLoad={({target:img}) => onLoadedImg(img, tile)} className={classes.imageView} src={GetImageURL(tile)} alt={GetStoredImageName(tile)} />
                <ImageListItemBar
                  title={tile.dimension !== undefined ? tile.dimension : "-"}
                  subtitle={GetStoredImageName(tile)}
                  actionIcon={
                    <div style={{ width: 'min-content' }}>
                    <IconButton onClick={() => onImageSelected(GetStoredImageId(tile), GetImageURL(tile))} aria-label={`info about ${"tile.title"}`} className={classes.icon}>
                      <CheckCircleIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditStoredImage(GetStoredImageId(tile), GetStoredImageClient(tile))} aria-label={`info about ${"tile.title"}`} className={classes.icon}>
                    <DeleteIcon />
                  </IconButton>
                  </div>
                    
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
      </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleShowAddStoredImage}>
            Upload New Image
        </Button>
        <Button variant="secondary" onClick={handleCloseViewStoredImages}>
            Cancel
        </Button>
        </Modal.Footer>
      </Modal>

      <Modal
            className={classes.content}
            show={showAddStoredImage}
            onHide={handleCloseAddStoredImage}
            centered>
            <Modal.Header closeButton>
            <Modal.Title>{"Upload Image"}</Modal.Title>
            </Modal.Header>
            <Modal.Body><div>{"Image You Want to Upload"}</div>
            
                {/* <ImageUploader
                singleImage={true}
                withPreview={true}
                withIcon={true}
                buttonText='Choose images'
                onChange={onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
            /> */}
            <ImageUploading
                    multiple={false}
                    value={picturesInput}
                    onChange={onDrop}
                    maxNumber={1}
                    maxFileSize={5242880}
                    acceptType={['jpg', 'gif', 'png', 'gif']}
                    dataURLKey="data_url">
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          {picturesInput.length == 0 && <button
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click or Drop here
                          </button>}
                          &nbsp;
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image['data_url']} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button onClick={() => onImageUpdate(index)}>Update</button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleUploadCall}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleCloseAddStoredImage}>
                Cancel
            </Button>
            </Modal.Footer>
        </Modal>

    </div>
  );
};

ImagePreviewGrid.propTypes = {
  className: PropTypes.string
};

export default withAuthorization(ImagePreviewGrid);