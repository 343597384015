import moment from "moment";
import { NumericFormat } from 'react-number-format';
import React from "react";
import SessionHelper from "helpers/SessionHelper";

export function GetBetfullyTransactionId(transaction){
  if(transaction.Id){
    return transaction.Id
  } else{
    return 0
  }
}
//The unique ID of the transaction.
export function GetPlaidTransactionId(transaction){
  if (transaction.PlaidTransactionId) {
    return transaction.PlaidTransactionId;
  } else {
    return "n/a";
  }
}
//The ID of the account in which this transaction occurred.
export function GetPlaidAccountId(transaction){
  if (transaction.PlaidAccountId) {
    return transaction.PlaidAccountId;
  } else {
    return "n/a";
  }
}
//nullable The ID of the category to which this transaction belongs. See Categories.
export function GetPlaidCategoryId(transaction){
  if (transaction.PlaidCategoryId) {
    return transaction.PlaidCategoryId;
  } else {
    return "n/a";
  }
}
//digital: transactions that took place online.
//place: transactions that were made at a physical location.
//special: transactions that relate to banks, e.g.fees or deposits.
//unresolved: transactions that do not fit into the other three types.
export function GetPlaidTransactionType(transaction){
  if (transaction.PlaidTransactionType) {
    return transaction.PlaidTransactionType;
  } else {
    return "n/a";
  }
}
//The merchant name or transaction description.
export function GetPlaidName(transaction){
  if (transaction.PlaidName) {
    return transaction.PlaidName;
  } else {
    return "n/a";
  }
}
//The settled dollar value. Positive values when money moves out of the account; 
//negative values when money moves in. For example, purchases are positive; 
//credit card payments, direct deposits, refunds are negative.
export function GetPlaidAmount(transaction){
  if(transaction.PlaidAmount){
    return transaction.PlaidAmount
  } else{
    return 0
  }
}

export function GetPlaidAmountFormatted(transaction){
  if(transaction.PlaidAmount){
    return <NumericFormat decimalScale={2} fixedDecimalScale={true} value={transaction.PlaidAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
  } else{
    return <NumericFormat decimalScale={2} fixedDecimalScale={true} value={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
  }
}

//nullable The ISO currency code of the transaction. Always null if unofficial_currency_code is non-null.
export function GetPlaidIsoCurrencyCode(transaction){
  if (transaction.PlaidIsoCurrencyCode) {
    return transaction.PlaidIsoCurrencyCode;
  } else {
    return "n/a";
  }
}
//nullable The unofficial currency code associated with the transaction.Always null if iso_currency_code is non-null.
export function GetPlaidUnofficialCurrencyCode(transaction){
  if (transaction.PlaidUnofficialCurrencyCode) {
    return transaction.PlaidUnofficialCurrencyCode;
  } else {
    return "n/a";
  }
}
//For pending transactions, Plaid returns the date the transaction occurred; for posted transactions, 
//Plaid returns the date the transaction posts.Both dates are returned in an ISO 8601 format (YYYY-MM-DD).
export function GetPlaidDateString(transaction){
  if (transaction.PlaidDate) {
    return transaction.PlaidDate;
  } else {
    return "";
  }
}

export function GetPlaidDate(transaction) {
  if (transaction.PlaidDate) {
    return moment(transaction.PlaidDate, "YYYY-MM-DD").format("MM/DD/YY");
  } else {
    return "";
  }
}

//When true, identifies the transaction as pending or unsettled.Pending transaction details (name, type, amount, category ID) may change before they are settled.
export function GetPlaidPending(transaction){
  if (transaction.PlaidPending) {
    return transaction.PlaidPending;
  } else {
    return false;
  }
}
//nullable The ID of a posted transaction's associated pending transaction—where applicable.
export function GetPlaidPendingTransactionId(transaction){
  if (transaction.PlaidPendingTransactionId) {
    return transaction.PlaidPendingTransactionId;
  } else {
    return "n/a";
  }
}
//nullable The name of the account owner.This field is not typically populated and only relevant when dealing with sub-accounts.
export function GetPlaidAccountOwner(transaction){
  if (transaction.PlaidAccountOwner) {
    return transaction.PlaidAccountOwner;
  } else {
    return "n/a";
  }
}

export function GetDateCreated(transaction) {
  if (transaction.DateCreated) {
    return moment(transaction.DateCreated, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YY  HH:mm:ss");
  } else {
    return "";
  }
}

export function GetLastPlaidUpdate(transaction) {
  if (transaction.LastPlaidUpdate) {
    return moment(transaction.LastPlaidUpdate, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YY  HH:mm:ss");
  } else {
    return "";
  }
}

export function GetStatusInt(transaction){
  if (transaction.Status) {
    return transaction.Status;
  } else {
    return "n/a";
  }
}

export function GetProcessed(transaction){
  if (transaction.Processed) {
    return transaction.Processed;
  } else {
    return false;
  }
}

export function GetKeywordId(transaction){
  if(transaction.KeywordId){
    return transaction.KeywordId
  } else{
    return 0
  }
}

export function GetWord(transaction){
  if(transaction.Word){
    return transaction.Word
  } else{
    return "n/a"
  }
}

export function GetPointsAwarded(transaction){
  if(transaction.PointsAwarded){
    return transaction.PointsAwarded
  } else{
    return 0
  }
}

export function GetClientId(transaction){
  if(transaction.CompanyId){
    return transaction.CompanyId
  } else{
    return 0
  }
}

export function GetClientName(transaction){
  var name = "n/a"
  const companyList = SessionHelper.getCompanyList();
  if(companyList !== undefined && companyList != null){
    const found = companyList.find(element => element.Id == GetClientId(transaction));
    if(found){
      name = found.Name;
    }
  }
  return name;
}

export function GetBetfullyType(transaction){
  if(transaction.ItemType){
    switch (transaction.ItemType) {
      case 0:
        return "Unknown";
      case 1:
        return "Plaid Transaction";
      case 2:
        return "Ad";
      case 3:
        return "Admin Adjustment";
      case 4:
        return "Internal Offer";
      case 5:
        return "Payout"
    }
  } 
  return "Unknown"
}

export function GetMerchantName(transaction){
  if(transaction.MerchantName){
    return transaction.MerchantName
  } else{
    return "n/a"
  }
}

export function GetStatus(transaction){
  if(transaction !== null ) {
    switch (transaction.Status) {
      case TransactionStatusToInt.None:
        return TransactionStatusToString.None;
      case TransactionStatusToInt.Active:
        return TransactionStatusToString.Active;
      case TransactionStatusToInt.Pending:
        return TransactionStatusToString.Pending;
      case TransactionStatusToInt.Cancelled:
        return TransactionStatusToString.Cancelled;
      case TransactionStatusToInt.Removed:
        return TransactionStatusToString.Removed;
    }
  }
  return "n/a"
}

export const TransactionStatusToString = {
  None: "None",
  Active: "Active",
  Pending: "Pending",
  Cancelled : "Cancelled",
  Removed: "Removed"
}

export const TransactionStatusToInt = {
  None: 0,
  Active: 1,
  Pending: 2,
  Cancelled: 3,
  Removed: 4
}
